// -------------------------------------------------------------------
//  shops-detail.scss
// -------------------------------------------------------------------
.shops-detail{
    .sec-shop-detail{
        .shop-logo{
            text-align: center;
            max-width: 160px;
            margin: 40px auto 80px;
            @include media(tb) {
                max-width: 140px;
                margin: 30px auto 60px;
            }
            @include media(sp) {
                max-width: 120px;
                margin: 20px auto 40px;
            }
        }
        .page-title{
            font-weight: 400;
            text-align: center;
            line-height: 1.2;
            margin-top: 20px;
            .gyoutai{
                display: block;
                margin-bottom: 5px;
                font-size: 2.0rem;
                @include media(sp) {
                    font-size: 1.4rem;
                }
            }
            .main{
                display: block;
                font-size: 4.0rem;
                @include media(sp) {
                    font-size: 3.0rem;
                }
            }
            .sub{
                display: block;
                margin-top: 10px;
                text-align: center;
                font-size: 2.0rem;
                @include media(sp) {
                    font-size: 1.6rem;
                }
            }
        }
        .shop-area{
            margin-top: 40px;
            display: flex;
            justify-content: center;
            @include media(sp) {
                margin-top: 20px;
            }
            span{
                display: inline-block;
                background: $BLACK_COLOR;
                color: $WHITE_COLOR;
                font-size: 1.2rem;
                line-height: 1.2;
                padding: 5px 10px;
                margin: 0 5px;
            }
        }
        .shop-copy{
            text-align: center;
            max-width: 1000px;
            font-size: 3.0rem;
            margin: 60px auto 0;
            @include media(sp) {
                font-size: 2.0rem;
                margin-top: 30px;
            }
        }
        .shop-description{
            max-width: 1000px;
            font-size: 1.4rem;
            margin: 60px auto 0;
            @include media(sp) {
                font-size: 1.2rem;
                margin-top: 30px;
            }
        }
        .shop-tel{
            margin: 40px 0 0;
            text-align: center;
            font-size: 4.0rem;
            @include media(tb) {
                font-size: 3.0rem;
            }
            @include media(sp) {
                font-size: 2.0rem;
            }
            i{
                margin-right: 20px;
                font-weight: 100;
                @include media(sp) {
                    margin-right: 10px;
                }
            }
            a{
                color: $BLACK_COLOR;
                text-decoration: underline;
                letter-spacing: 0.1em;
            }
        }
        .shop-link{
            margin: 40px 0 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            a{
                display: block;
                margin: 10px 10px;
                @include flex_inner_center;
                @include media(tb) {
                    max-width: 300px;
                }
                @include media(sp) {
                    width: 100%;
                }
            }
        }
        .sns-list{
            display: flex;
            justify-content: center;
            margin-top: 40px;
        }
        .sns-item{
            margin: 0 20px;
            a{
                color: $BLACK_COLOR;
                i{
                    font-size: 3.0rem;
                }
                &:after{
                    display: none;
                }
            }
        }
        .shop-map{
            max-width: 1000px;
            margin: 40px auto 0;
            iframe{
                width: 100%;
                @include media(sp) {
                    height: 60vw;
                }
            }
        }
    }
    .sec-shop-information{
        background: $BEIGE_COLOR1;
        padding: 100px 0;
        @include media(wide) {
            padding: 90px 0;
        }
        @include media(hd) {
            padding: 80px 0;
        }
        @include media(tb) {
            padding: 60px 0;
        }
        @include media(sp) {
            padding: 40px 0;
        }
        .shop-information-container{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            @include media(sp) {
                border-top: 1px dotted $BLACK_COLOR;
                padding-top: 20px;
            }
        }
        dl{
            width: calc(50% - 20px);
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            @include media(tb) {
                width: 100%;
            }
            dt{
                width: 150px;
                font-weight: bold;
                @include media(sp) {
                    width: 100%;
                    font-size: 1.4rem;
                }
            }
            dd{
                width: calc(100% - 150px);
                margin-bottom: 40px;
                @include media(tb) {
                    margin-bottom: 20px;
                }
                @include media(sp) {
                    width: 100%;
                    font-size: 1.2rem;
                    border-bottom: 1px dotted $BLACK_COLOR;
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                }
                a:not(.btn-black){
                    color: $BLACK_COLOR;
                    text-decoration: underline;
                }
                a.btn-black{
                    margin-top: 10px;
                }
                &.delivery{
                    display: flex;
                    flex-wrap: wrap;
                    a{
                        display: block;
                        margin-right: 5px;
                        margin-bottom: 5px;
                        &:after{
                            display: none;
                        }
                        img{
                            width: 64px;
                        }
                    }
                }
            }
        }
    }
}
