// -------------------------------------------------------------------
//  press.scss
// -------------------------------------------------------------------
.press{
    .sec-press{
        .press-lead{
            //border: 1px solid $BORDER_COLOR;
            margin-bottom: 50px;
            padding: 50px 0px 50px;
            @include media(wide){
                margin-bottom: 45px;
                padding: 45px 0px;
            }
            @include media(hd){
                margin-bottom: 40px;
                padding: 40px 0px;
            }
            @include media(tb){
                margin-bottom: 30px;
                padding: 30px 0px;
            }
            @include media(sp){
                margin-bottom: 20px;
                padding: 20px 0px;
            }
            .press-lead-title{
                text-align: center;
                font-size: 3.0rem;
                margin-bottom: 40px;
                @include media(tb){
                    font-size: 2.4rem;
                    margin-bottom: 30px;
                }
                @include media(sp){
                    font-size: 1.8rem;
                    margin-bottom: 20px;
                }
            }
            .press-text{
                text-align: center;
                @include media(tb){
                    text-align: left;
                }
            }
            .link-container{
                max-width: 1000px;
                margin-top: 40px;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                @include media(tb){
                    margin-top: 30px;
                }
                @include media(sp){
                    margin-top: 20px;
                }
                .btn-black{
                    width: calc(50% - 20px);
                    max-width: inherit;;
                    @include media(tb){
                        width: 100%;
                        margin-bottom: 10px;
                    }
                    @include media(sp){
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }

}