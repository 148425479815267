// -------------------------------------------------------------------
//  news.scss
// -------------------------------------------------------------------
.news{
    .sec-news{
        .news-category{
            margin-bottom: 60px;
            @include media(tb){
                margin-bottom: 40px;
            }
            @include media(sp){
                margin-bottom: 20px;
            }
            .category-list{
                display: flex;
                justify-content: center;
            }
            .category-item{
                width: 100%;
                a{
                    text-align: center;
                    color: $BLACK_COLOR;
                    display: block;
                    padding: 10px 20px;
                    border: 1px solid $BORDER_COLOR;
                    transition: all 0.5s ease;
                    &.is-selected, &:hover{
                        background: $DARK_GRAY_COLOR;
                        color: $WHITE_COLOR;
                        border-color: $DARK_GRAY_COLOR;
                        opacity: 1;
                    }
                }
            }
        }
    }
}
