// -------------------------------------------------------------------
//  variables.scss
// -------------------------------------------------------------------

// Width
// -------------------------------------------------------------------
$SITE_WIDTH: 1600px;
$SITE_WIDTH2: 1280px;
$BREAK_POINT_SP_1: 360px;
$BREAK_POINT_SP_2: 660px;
$BREAK_POINT_TB: 960px;
$BREAK_POINT_PC_1: 1280px;
$BREAK_POINT_PC_2: 1600px;

// Header Height
// -------------------------------------------------------------------
$HEADER_HEIGHT_PC: 80px;
$HEADER_HEIGHT_TB: 70px;
$HEADER_HEIGHT_SP: 70px;

// Font
// -------------------------------------------------------------------
$BASE_FONT_SIZE: 16;
$BASE_FONT_SIZE_WIDE: 15;
$BASE_FONT_SIZE_HD: 15;
$BASE_FONT_SIZE_TB: 14;
$BASE_FONT_SIZE_SP: 12;
$BASE_LINE_HEIGHT: 2.0;
$BASE_FONT_FAMILY:  '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif;
$EN_FONT_FAMILY: sans-serif;
$FONT_AWESOME: "Font Awesome 5 Pro";

// Web Font
// -------------------------------------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;500;600&display=swap');
$MINCHO: 'Shippori Mincho', serif;
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP);
$NOTO_SANS_JP: 'Noto Sans JP', sans-serif;
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
$OPEN_SANS: 'Open Sans', sans-serif;
@import url(https://use.typekit.net/toj6bxg.css);
$CENTURY_GOTHIC: "century-gothic",sans-serif;


// Color
// -------------------------------------------------------------------
$BORDER_COLOR: #CCC;
$BASE_BG_COLOR: #FFFFFF;
$BASE_TEXT_COLOR: #1A1919;

$BLACK_COLOR: #000;
$WHITE_COLOR: #FFFFFF;
$OFF_WHITE_COLOR: #F0F0F0;
$LIGHT_GRAY_COLOR: #CCCCCC;
$GRAY_COLOR: #BDBDBD;
$DARK_GRAY_COLOR: #303030;

$BLUE_COLOR: #112D5A;
$RED_COLOR: #F07070;
$BEIGE_COLOR1: #f7f6f2;
$BEIGE_COLOR2: #efede5;
$BEIGE_COLOR3: #d2ccb9;
$BEIGE_COLOR4: #4a3d14;


$FOOTER_COLOR: #efeeed;

// Link Color
// -------------------------------------------------------------------
$LINK_COLOR: #B12920;
$LINK_HOVER_COLOR: #B12920;
$LINK_VISITED_COLOR: #B12920;
$LINK_ACTIVE_COLOR: #B12920;

// Image Path
// -------------------------------------------------------------------
$IMAGE_DIR: '../images/';
