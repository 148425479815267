// ----------------------------------
//  refine
// ----------------------------------
.refine{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    .btn-modal{
        transition: all 0.5s ease;
        display: inline-block;
        padding: 5px 30px;
        border: 1px solid $BLACK_COLOR;
        border-radius: 30px;
        color: $BLACK_COLOR;
        &:hover{
            opacity: 1;
            color: $WHITE_COLOR;
            background: $BLACK_COLOR;
        }
    }
    i{
        font-size: 1.6rem;
        display: inline-block;
        margin-left: 10px;
    }

    .refine-condition-container{
        padding: 20px;
        border-bottom: 1px dotted $BLACK_COLOR;
        @include media(sp){
            padding: 10px 0;
        }
        .refine-condition-title{
            font-size: 1.8rem;
            margin-bottom: 10px;
            @include media(sp){
                font-size: 1.4rem;
            }
        }
    }
    .scroll > .refine-condition-container:first-child{
        border-top: 1px dotted $BLACK_COLOR;
    }

    .btn-wrap{
        display: flex;
        justify-content: center;
        margin-top: 40px;
        @include media(sp){
            margin-top: 20px;
        }
        a{
            @include flex_inner_center
            height: 50px;
            width: 50%;
            max-width: 200px;
            margin: 0 10px;
            @include media(sp){
                height: 40px;
            }
        }
        .btn-cancel{
            border: 1px solid $BLACK_COLOR;
            color: $BLACK_COLOR;
        }
        .btn-refine{
            border: 1px solid $BLACK_COLOR;
            color: $WHITE_COLOR;
            background: $BLACK_COLOR;
        }
    }
}
