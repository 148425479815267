// -------------------------------------------------------------------
//  footer.scss
// -------------------------------------------------------------------
@include media(tb){
}
@include media(sp){
}

.footer {
    // border-top: 1px solid $BORDER_COLOR;
    background: $FOOTER_COLOR;
    margin-top: 100px;
    padding-top: 60px;
    color: $BLACK_COLOR;
    @include media(wide) {
        margin-top: 90px;
        padding-top: 50px;
    }
    @include media(hd) {
        margin-top: 80px;
        padding-top: 40px;
    }
    @include media(tb) {
        margin-top: 60px;
        padding-top: 30px;
    }
    @include media(sp) {
        margin-top: 40px;
        padding-top: 20px;
    }
    .footer-navi{
        .inner{
            @include flex_space_between;
            padding: 0 80px 60px;
            @include media(wide){
                padding: 0 60px 50px;
            }
            @include media(hd){
                padding: 0 40px 40px;
            }
            @include media(tb){
                padding: 0 30px 30px;
            }
            @include media(sp){
                padding: 0 20px 20px;
            }
        }
    }
    .footer-navi-wrap{
        width: 500px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include media(hd){
        }
        @include media(tb){
            width: 100%;
        }
        h2{
            width: 100%;
            font-size: 2.6rem;
            font-weight: normal;
            margin-bottom: 20px;
            @include media(sp){
                font-size: 1.8rem;
                margin-bottom: 0;
            }
        }
        .navi-list{
            @include media(tb){
                width: 50%;
            }
            @include media(sp){
                margin-right: 0px;
                width: 100%;
            }
        }
        .navi-item{
            @include fontsize_small;
            margin-bottom: 0px;
            @include media(sp){
                margin-bottom: 0px;
            }
            a{
                font-size: 1.8rem;
                color: $BLACK_COLOR;
                padding: 3px 10px;
                position: relative;
                display: block;
                transition: color 0.5s ease;
                &[target="_blank"]:after{
                    background-image: url(../images/common/ico_window_bk.svg);
                }
                &:before{
                    content: "";
                    display: block;
                    width: 0;
                    height: 100%;
                    background: $BLACK_COLOR;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    transition: width 0.5s ease;
                }
                &:hover{
                    opacity: 1;
                    color: $WHITE_COLOR;
                    &:before{
                        width: 100%;
                    }
                    &[target="_blank"]:after{
                        background-image: url(../images/common/ico_window_wh.svg);
                    }
                }
                &:after{
                    position:relative;
                }
                span{
                    position: relative;
                    z-index: 1;
                }
                @include media(sp){
                    font-size: 1.6rem;
                    position: relative;
                    display: block;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 0px;
                    padding-right: 20px;
                    border-bottom: 1px dotted $GRAY_COLOR;
                    &:after{
                        font-family: $FONT_AWESOME;
                        content: '\f054';
                        display: block;
                        position: absolute;
                        right: 5px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &[target="_blank"]:after{
                        content: "";
                        right: 0;
                    }
                }
            }
        }
        .sns-wrap{
            width: 100%;
            margin-top: 20px;
            display: flex;
            align-items: center;
            @include media(tb) {
                flex-wrap: wrap;
                margin-bottom: 20px;
            }
        }
        .sns-list{
            display: flex;
            justify-content: left;
            @include media(tb) {
                justify-content: center;
                width: 100%;
            }
            @include media(sp) {
                flex-wrap: wrap;
            }
        }
        .sns-item{
            margin: 0 10px;
            
            @include media(sp) {
                margin: 0 10px;
            }
            i{
                //position: absolute;
                font-size: 3.0rem;
                //margin-right: 5px;
                vertical-align: bottom;
                left: 0;
                //top: -4px;
                @include media(tb) {
                    font-size: 4.0rem;
                }
                @include media(narrow) {
                    font-size: 3.5rem;
                }
            }
            a{
                color: $BLACK_COLOR;
                font-size: 1.6rem;
                position: relative;
                //padding-left: 35px;
            }
            a[target="_blank"]:after{
                display: none;
            }
        }
        .link-corpolate{
            margin-left: 20px;
            @include media(tb) {
                width: 100%;
                max-width: 300px;
                margin: 20px auto 0;
                text-align: center;
            }
            a{
                width:280px;
                font-size: 1.4rem;
            }
        }
    }
    .footer-launch-wrap{
        max-width: calc(100% - 560px);
        @include media(hd){
            max-width: calc(100% - 520px);
        }
        @include media(tb){
            max-width: inherit;
            width: 100%;
            margin-top: 20px;
        }
        .launch-list{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .launch-item{
            width: calc(33.33% - 10px);
            margin-top: 20px;
            @include media(hd){
                width: calc(50% - 10px);
            }
            @include media(sp){
                width: 100%;
                margin-top: 10px;
            }
            a{
                .launch-image{
                    position: relative;
                    height: 180px;
                    @include media(hd){
                        height: 100px;
                    }
                    @include media(tb){
                        height: 160px;
                    }
                    @include media(sp){
                        height: 140px;
                    }
                    span{
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        @include flex_inner_center;
                        z-index: 1;
                        background: rgba(0,0,0,0.2);
                        color: $WHITE_COLOR;
                        font-size: 2.0rem;
                    }
                }
                .launch-text{
                    margin-top: 2px;
                    font-size: 1.2rem;
                    color: $BLACK_COLOR;
                    @include media(hd){
                        font-size: 1.0rem;
                    }
                }
                &:hover{
                    opacity: 1;
                    .launch-image{
                        span{
                            background: rgba(0,0,0,0.8);
                        }
                    }
                }
                &[target="_blank"]{
                    &:after{
                        display: none;
                    }
                    .launch-title span{
                        font-weight: 100;
                        line-height: 1.2;
                    }
                    .launch-title span:after{
                        margin: 0px 0 0 4px;
                        content: "";
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        background: url(../images/common/ico_window_bk.svg) no-repeat center center;
                        vertical-align: middle;
                    }
                }
            }
        }
/*
        .launch-list{
        }
        .launch-item{
            margin-bottom: 10px;
            @include media(sp){
                margin-bottom: 20px;
            }
            a{
                display: block;
                position: relative;
                color: $WHITE_COLOR;
                padding: 10px 20px 10px 30px;
                @include media(hd){
                }
                @include media(sp){
                    padding: 0px 0px 0px 15px;
                }
                &:before{
                    content: "";
                    display: block;
                    width: 0;
                    height: 100%;
                    background: $BLACK_COLOR;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    transition: width 0.5s ease;
                }
                &:hover{
                    opacity: 1;
                    &:before{
                        width: 100%;
                    }
                }
                &:after{
                    content: "";
                    display: block;
                    width:5px;
                    height: calc(100% - 20px);
                    background: $LIGHT_GRAY_COLOR;
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    margin: 0;
                    @include media(sp){
                        width: 3px;
                        top: 0px;
                        left: 0px;
                        height: 100%;
                    }
                }
                span{
                    display: block;
                    line-height: 1.6;
                    position: relative;
                    z-index: 1;
                    &.en{
                        font-size: 2.2rem;
                        font-weight: bold;
                        @include media(sp){
                            font-size: 1.8rem;
                        }
                    }
                    &.jp{
                        font-size: 1.2rem;
                        @include media(sp){
                            font-size: 1.0rem;
                        }
                    }
                }
            }
        }
*/
    }
    .footer-banner{
        .inner{
            padding: 0 80px 0px;
            @include media(wide){
                padding: 0 60px 0px;
            }
            @include media(hd){
                padding: 0 40px 0px;
            }
            @include media(tb){
                padding: 0 30px 0px;
            }
            @include media(sp){
                padding: 0 20px 0px;
            }
        }
        background: $WHITE_COLOR;
        border-bottom: 1px solid $FOOTER_COLOR;
        padding: 30px 0;
        @include media(tb) {
            padding: 20px 0;
        }
        @include media(sp) {
            padding: 10px 0;
        }
        .banner-list{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: 0 auto;
            @include media(sp) {
                justify-content: space-between;
            }
        }
        .banner-item{
            margin: 10px 10px;
            width: calc(20% - 20px);
            @include media(tb) {
                width: calc(33.33% - 20px);
            }
            @include media(sp) {
                margin: 10px 0px;
                width: calc(50% - 10px);
            }
            img{
                width: auto;
            }
            a:after{
                display: none;
            }
        }
    }
    .footer-copyright{
        .inner{
            padding: 0 80px 0px;
            @include media(wide){
                padding: 0 60px 0px;
            }
            @include media(hd){
                padding: 0 40px 0px;
            }
            @include media(tb){
                padding: 0 30px 0px;
            }
            @include media(sp){
                padding: 0 20px 0px;
            }
        }
        background: $WHITE_COLOR;
        color: $BLACK_COLOR;
        height: 60px;
        line-height: 60px;
        @include media(sp) {
            height: 50px;
            line-height: 50px;
        }
        a{
            color: $WHITE_COLOR;
        }
        .footer-copyright-wrap{
            @include media(tb) {
                display: block;
            }
        }
        p{
            text-align: right;
            font-size: 1.4rem;
            font-family: $CENTURY_GOTHIC;
            @include media(tb) {
                font-size: 1.2rem;
                margin-top: 20px;
                text-align: center;
            }
            @include media(sp) {
                font-size: 1.2rem;
                margin-top: 10px;
                text-align: center;
            }
        }
    }


/*
    .footer-navi-sns{
        padding: 30px 0;
        border-top: 10px solid $LIGHT_GRAY_COLOR;
        border-bottom: 10px solid $LIGHT_GRAY_COLOR;
        @include media(tb) {
            padding: 30px 0;
        }
        .sns-ttl{
            font-family: $COPPERPLATE;
            font-weight: bold;
            font-size: 2.0rem;
            text-align: center;
            margin-bottom: 20px;
            &:before{
                content: "";
                display: inline-block;
                width: 1px;
                height: 30px;
                background: $BLACK_COLOR;
                transform: rotate(-30deg) translateY(10px);
                margin-right: 30px;
            }
            &:after{
                content: "";
                display: inline-block;
                width: 1px;
                height: 30px;
                background: $BLACK_COLOR;
                transform: rotate(30deg) translateY(10px);
                margin-left: 30px;
            }
            @include media(sp) {
                font-size: 1.6rem;
                margin-bottom: 10px;
            }
            @include media(narrow) {
                font-size: 1.4rem;
                margin-bottom: 10px;
            }
        }
        .sns-wrap{
            display: flex;
            justify-content: center;
            @include media(sp) {
                flex-wrap: wrap;
            }
        }
        .sns-sub-ttl{
            font-family: $COPPERPLATE;
        }
        .sns-list{
            display: flex;
        }
        .sns-item{
            margin: 0 10px;
            @include media(sp) {
                margin: 0 10px;
            }
            i{
                //position: absolute;
                font-size: 4.0rem;
                //margin-right: 5px;
                vertical-align: bottom;
                left: 0;
                //top: -4px;
                @include media(tb) {
                    font-size: 3.5rem;
                }
                @include media(narrow) {
                    font-size: 3.0rem;
                }
            }
        }
        a{
            color: $BLACK_COLOR;
            font-size: 1.6rem;
            position: relative;
            //padding-left: 35px;
        }
        a[target="_blank"]:after{
            display: none;
        }
    }
    .footer-navi{
        font-family: $COPPERPLATE;
        background: $WHITE_COLOR;
        padding: 40px 0;
        border-top: 10px solid $LIGHT_GRAY_COLOR;
        @include media(tb) {
            padding: 30px 0 20px;
        }
        a{
            color: $WHITE_COLOR;
        }
        .inner{
            @include flex_space_between;
            align-items: center;
            @include media(hd) {
                display: block;
            }
        }
        .footer-navi-logo{
            position: relative;
            text-align: center;
            @include media(hd) {
                img{
                    width: 140px;
                    margin: 0 auto 40px;
                }
            }
            @include media(sp) {
                img{
                    width: 120px;
                }
            }
            img{
                width: 160px;
                @include media(sp) {
                    width: 140px;
                    margin-bottom: 20px;
                }
            }
        }
        .footer-navi-wrap{
            display: flex;
            justify-content: flex-end;
            @include media(hd) {
                justify-content: space-between;
            }
            @include media(sp) {
                flex-direction: column;
            }
            .navi-list{
                margin-left: 60px;
                &:last-child{
                    @include media(sp) {
                        .navi-item{
                            margin-bottom: 0;
                        }
                    }
                }
                @include media(hd) {
                    margin-left: 0;
                }
                @include media(sp) {
                    margin-left: 0px;
                }
            }
            .navi-item{
                font-size: 1.6rem;
                margin-bottom: 20px;
                @include media(hd) {
                    font-size: 1.4rem;
                    margin-bottom: 20px;
                }
                @include media(sp) {
                    font-size: 1.6rem;
                    margin-bottom: 0px;
                }
                & > a{
                    display: block;
                    //border-bottom: 1px solid #8EA2C1;
                    margin-bottom: 5px;
                    //padding-bottom: 5px;
                    position: relative;
                    color: $BLACK_COLOR;
                    @include media(sp) {
                        margin-bottom: 0px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        padding-right: 20px;
                        border-bottom: 1px dotted $GRAY_COLOR;
                        &:after{
                            font-family: $FONT_AWESOME;
                            content: '\f054';
                            display: block;
                            position: absolute;
                            right: 5px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        &[target="_blank"]:after{
                            //content: "\f08e";
                            content: "";
                        }
                    }
                }
            }
            .sub-navi-list{
                padding-left: 10px;
            }
            .sub-navi-item{
                opacity: 0.8;
                font-size: 1.5rem;
                @include media(hd) {
                    font-size: 1.4rem;
                }
                @include media(sp) {
                    width: 100%;
                    font-size: 1.4rem;
                    margin-top: 5px;
                }
                a{
                    display: block;
                    color: $GRAY_COLOR;
                    position: relative;
                    @include media(sp) {
                        margin-top: 0px;
                        padding-top: 3px;
                        padding-bottom: 3px;
                        padding-right: 20px;
                        &:after{
                            font-family: $FONT_AWESOME;
                            content: '\f054';
                            display: block;
                            position: absolute;
                            right: 5px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        &[target="_blank"]:after{
                            //content: "\f08e";
                            content: "";
                        }
                    }
                }
            }
        }
    }
    .footer-copyright{
        background: $BLACK_COLOR;
        color: $WHITE_COLOR;
        font-size: 1.4rem;
        a{
            color: $WHITE_COLOR;
        }
        .footer-copyright-wrap{
            display: flex;
            justify-content: space-between;;
            @include media(tb) {
                display: block;
            }
        }
        .link-list{
            display: flex;
            margin: 0 0px;
            @include media(tb) {
                justify-content: center;
            }
            @include media(sp){
                flex-direction: column;
                margin: 0 0px;
            }
        }
        .link-item{
            margin: 0 20px 0 0;
            @include media(sp){
                font-size: 1.2rem;
                margin: 0 0 10px;
            }
            a{
                &:before{
                    font-family: $FONT_AWESOME;
                    content: '\f054';
                    display: inline-block;
                    margin-right: 5px;
                }
            }
        }
        p{
            font-size: 1.2rem;
            font-family: $COPPERPLATE;
            @include media(tb) {
                font-size: 1.0rem;
                text-align: center;
            }
        }
    }
*/
}

.pagetop{
    position: fixed;
    z-index: 100;
    right: 0px;
    bottom: 0px;
    a{
        display: block;
        width: 60px;
        height: 60px;
        background: $BLACK_COLOR;
        color: $WHITE_COLOR;
        @include flex_inner_center;
        font-size: 2.0rem;
        @include media(sp){
            width: 50px;
            height: 50px;
            font-size: 2.0rem;
        }
    }
}