// -------------------------------------------------------------------
//  main.scss
// -------------------------------------------------------------------
.main {
    display: block;
    &.pt{
        padding-top: $HEADER_HEIGHT_PC;
        @include media(wide) {
        }
        @include media(hd) {
        }
        @include media(tb) {
            padding-top: $HEADER_HEIGHT_TB;
        }
        @include media(sp) {
            padding-top: $HEADER_HEIGHT_SP;
        }
        @include media(narrow) {
        }
    }
}
.sec-content{
    position: relative;
    margin-bottom: 100px;
    &:last-child{
        margin-bottom: 0px;
    }
    @include media(wide) {
        margin-bottom: 90px;
    }
    @include media(hd) {
        margin-bottom: 80px;
    }
    @include media(tb) {
        margin-bottom: 60px;
    }
    @include media(sp) {
        margin-bottom: 40px;
    }
    .sec-ttl{
        text-align: center;
        font-size: 3.0rem;
        margin-bottom: 30px;
        @include media(tb) {
            font-size: 2.4rem;
            margin-bottom: 20px;
        }
        @include media(sp) {
            font-size: 1.8rem;
            margin-bottom: 10px;
        }
    }
    .sec-sub-content{
        margin-top: 50px;
        @include media(hd) {
            margin-top: 40px;
        }
        @include media(tb) {
            margin-top: 30px;
        }
        @include media(sp) {
            margin-top: 20px;
        }
    }
    .sub-sec-ttl{
        font-size: 2.0rem;
        margin-bottom: 20px;
        @include media(tb) {
            font-size: 1.6rem;
            margin-bottom: 10px;
        }
        @include media(sp) {
            font-size: 1.4rem;
            margin-bottom: 10px;
        }
    }
    .image-list{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 10px;
    }
    .image-item{
        width: calc(33.33% - 20px);
        margin-bottom: 10px;
        @include media(tb) {
            width: calc(33.33% - 10px);
        }
        @include media(sp) {
            width: calc(50% - 5px);
        }
        figure{
            figcaption{
                margin-top: 5px;
                line-height: 1.4;
                font-size: 1.4rem;
                color: $GRAY_COLOR;
                @include media(tb) {
                    font-size: 1.2rem;
                }
                @include media(sp) {
                    font-size: 1.0rem;
                }
            }
        }
    }
    p.sec-text{
        margin-bottom: 20px;
    }
    .center{
        text-align: center;
        a{
            margin: 20px auto 0;
        }
    }
    ul.text-list{
        padding-left: 40px;
        margin-bottom: 20px;
        @include media(sp) {
            padding-left: 20px;
        }
    }
    li.text-item{
        list-style: outside disc;
        margin-bottom: 10px;
        line-height: 1.4;
    }
    .notfound{
        text-align: center;
        padding: 40px 0;
    }
}

.sec-title{
    position: relative;
    text-align: center;
    margin-bottom: 60px;
    padding-bottom: 40px;
    @include media(wide) {
    }
    @include media(hd) {
    }
    @include media(tb) {
        margin-bottom: 30px;
        padding-bottom: 20px;
    }
    @include media(sp) {
    }
    &:after{
        content: "";
        display: block;
        width: 100px;
        height: 1px;
        background: $BLACK_COLOR;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        @include media(tb) {
            width: 80px;
        }
    }
    .en{
        text-transform: uppercase;
        line-height: 1.2;
        display: block;
        font-family: $CENTURY_GOTHIC;
        font-weight: 400;
        font-size: 4.0rem;
        @include media(wide) {
            font-size: 3.8rem;
        }
        @include media(hd) {
            font-size: 3.6rem;
        }
        @include media(tb) {
            font-size: 2.6rem;
        }
        @include media(sp) {
            font-size: 2.4rem;
        }
    }
    .jp{
        line-height: 1.2;
        display: block;
        font-weight: 400;
        margin-top: 20px;
        font-size: 2.4rem;
        @include media(wide) {
            font-size: 2.2rem;
        }
        @include media(hd) {
            font-size: 2.0rem;
        }
        @include media(tb) {
            margin-top: 10px;
            font-size: 1.8rem;
        }
        @include media(sp) {
            font-size: 1.6rem;
        }
    }
}
.page-title-mv{
    position: relative;
    width: 100%;
    height: 60vh;
    min-height: 300px;
    max-height: 600px;
    overflow: hidden;
    @include media(tb) {
        height: 80vw;
        max-height: 400px;
    }
    .mv-image{
        position: relative;
        width: 100%;
        height: 100%;
        &:before{
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.4);
            transition: background 0.5s ease;
        }
        img{
            width: 100%;
            height: 100%;
        }
    }
    .page-title{
        position: absolute;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        @include flex_inner_center;
        color: $WHITE_COLOR;
        font-weight: 400;
        text-align: center;
        line-height: 1.2;
        .en{
            font-size: 6.0rem;
            @include media(tb) {
                font-size: 5.0rem;
            }
            @include media(sp) {
                font-size: 3.0rem;
            }
        }
        .jp{
            font-size: 4.0rem;
            @include media(tb) {
                font-size: 3.0rem;
            }
            @include media(sp) {
                font-size: 2.0rem;
            }
        }
    }
}
.page-title-text{
    position: relative;
    width: 100%;
    padding: 140px 0 60px;
    @include media(tb) {
        padding: 120px 0 50px;
    }
    @include media(sp) {
        padding: 110px 0 40px;
    }
    .page-title{
        width: 100%;
        height: 100%;
        @include flex_inner_center;
        font-weight: 400;
        text-align: center;
        line-height: 1.2;
        .en{
            font-size: 6.0rem;
            @include media(tb) {
                font-size: 5.0rem;
            }
            @include media(sp) {
                font-size: 3.0rem;
            }
        }
        .jp{
            font-size: 4.0rem;
            @include media(tb) {
                font-size: 3.0rem;
            }
            @include media(sp) {
                font-size: 2.0rem;
            }
        }
    }
}
.page-title-text-image{
    position: relative;
    width: 100%;
    padding: 100px 0 20px;
    @include media(tb) {
        padding: 90px 0 20px;
    }
    @include media(sp) {
        padding: 90px 0 20px;
    }
    .page-title{
        width: 100%;
        height: 100%;
        @include flex_inner_center;
        font-weight: 400;
        text-align: center;
        line-height: 1.2;
        margin-bottom: 40px;
        @include media(tb) {
            margin-bottom: 30px;
        }
        @include media(sp) {
            margin-bottom: 20px;
        }
        .en{
            font-size: 6.0rem;
            @include media(tb) {
                font-size: 5.0rem;
            }
            @include media(sp) {
                font-size: 3.0rem;
            }
        }
        .jp{
            font-size: 4.0rem;
            @include media(tb) {
                font-size: 3.0rem;
            }
            @include media(sp) {
                font-size: 2.0rem;
            }
        }
    }
    .page-image{
        max-width: 1360px;
        margin: 20px auto 0;
        height: 600px;
        overflow: hidden;
        border-radius: 40px;
        position: relative;
        z-index: 1;
        @include media(tb) {
            border-radius: 0px;
            height: 400px;
        }
        @include media(sp) {
            height: 300px;
        }
    }
}
.page-title-slide{
    padding-top: 80px;
    @include media(tb) {
        padding-top: 70px;
    }
    @include media(sp) {
        padding-top: 70px;
    }
    .page-title-header{
        .page-title{
            font-size: 6.0rem;
            font-weight: 400;
            text-align: center;
            line-height: 1.2;
            margin-bottom: 20px;
            @include media(tb) {
                font-size: 5.0rem;
            }
            @include media(sp) {
                font-size: 3.0rem;
            }
        }
    }
    .swiper{
        position: relative;
        z-index: 2;
        .swiper-slide{
            background: transparent;
            border-radius: 40px;
            overflow: hidden;
            position: relative;
            z-index: 1;
            @include media(tb) {
                border-radius: 0px;
            }
            .slide-image{
                height: 35vw;
                max-height: 560px;
                overflow: hidden;
                @include flex_inner_center;
                @include media(tb){
                    height: 60vw;
                    max-height: inherit;
                }
                @include media(sp){
                    height: 60vw;
                    max-height: inherit;
                }
            }
        }
    }
}
.image-text-contaier{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 400px;
    .image-container{
        width: 50%;
        @include media(tb) {
            width: 100%;
        }
        .image-wrap{
            border-radius: 0 50px 50px 0/0 50px 50px 0;
            overflow: hidden;
            height: 100%;
            position: relative;
            z-index: 1;
            @include media(tb) {
                border-radius: 0;
                height: 60vw;
                max-height: 400px;
            }
            .swiper-container{
                height: 100%;
            }
        }
    }
    .text-container{
        width: 50%;
        @include flex_inner_center;
        @include media(tb) {
            width: 100%;
        }
        .text-wrap{
            padding: 80px;
            max-width: 900px;
            @include media(wide) {
                padding: 70px;
            }
            @include media(hd) {
                padding: 60px;
            }
            @include media(tb) {
                padding: 40px;
            }
            @include media(sp) {
                padding: 20px;
            }
            .sec-link{
                margin-top: 60px;
                @include media(wide) {
                    margin-top: 50px;
                }
                @include media(hd) {
                    margin-top: 40px;
                }
                @include media(tb) {
                    margin-top: 30px;
                }
                @include media(sp) {
                    margin-top: 20px;
                }
                display: flex;
                justify-content: center;
            }
        }
    }
    &.reverse{
        flex-direction: row-reverse;
        .image-container{
            .image-wrap{
                border-radius: 50px 0 0 50px/50px 0 0 50px;
                @include media(tb) {
                    border-radius: 0;
                }
            }
        }
    }
}

.news-container{
    @include media(tb) {
        margin: 0 -40px;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0 40px 0px;
        display: flex;
        flex-wrap: wrap;
    }
    .news-list{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include media(tb) {
        }
        &:before{
            content:"";
            display: block;
            width: calc(25% - 30px);
            order:1;
            @include media(hd){
                width: calc(33.33% - 20px);
            }
            @include media(sp) {
                width: calc(50% - 10px);
            }
        }
        &:after{
            content:"";
            display: block;
            width: calc(25% - 30px);
            @include media(hd){
                width: calc(33.33% - 20px);
            }
            @include media(sp) {
                width: calc(50% - 10px);
            }
        }
    }
    .news-item{
        width: calc(25% - 20px);
        margin-bottom: 40px;
        @include media(hd){
            width: calc(33.33% - 20px);
        }
        @include media(sp) {
            width: calc(50% - 10px);
        }
        a{
            color: $BLACK_COLOR;
            display: block;
            width: 100%;
            &[target="_blank"]{
                &:after{
                    display: none;
                }
                .news-text:after{
                    width: 20px;
                    height: 20px;
                    content: "";
                    display: block;
                    background: url(../images/common/ico_window_bk.svg) no-repeat center center;
                    background-size: contain;
                    position: absolute;
                    top: 0px;
                    right: 0;
                    @include media(tb) {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
        .news-image{
            position: relative;
            height: 16vw;
            max-height: 350px;
            border-radius: 30px;
            overflow: hidden;
            @include media(hd){
                height: 20vw;
            }
            @include media(tb){
                height: 24vw;
                max-height: inherit;
                border-radius: 20px;
            }
            @include media(sp){
                height: calc(50vw - 40px);
                border-radius: 10px;
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
        .news-category{
            font-weight: 100;
            font-size: 1.2rem;
            position: absolute;
            right: 0;
            top: 0;
            text-transform: uppercase;
            line-height: 1.4;
            @include media(sp){
                font-size: 1.0rem;
            }
        }
        .news-text{
            position: relative;
            margin-top: 10px;
        }
        .news-date{
            margin-top: 5px;
            display: block;
            font-size: 1.2rem;
            line-height: 1.4;
            @include media(sp){
                font-size: 1.0rem;
            }
        }
        .news-related{
            display: inline-block;
            //background: $OFF_WHITE_COLOR;
            border: 1px solid $BORDER_COLOR;
            font-size: 1.2rem;
            line-height: 1.4;
            margin-top: 5px;
            padding: 4px 5px;
            //text-align: center;
            @include media(sp){
                font-size: 1.2rem;
            }
            span{
                display: inline-block;
                color: $BLACK_COLOR;
                padding: 2px 4px;
            }
        }
        .news-title{
            font-size: 1.4rem;
            font-weight: 100;
            line-height: 1.4;
            margin-top: 10px;
            @include media(sp){
                font-size: 1.2rem;
            }
        }
    }
}
.news-detail-container{
    max-width: 1000px;
    margin: 0 auto;
    .news-header{
        border-bottom: 1px solid $BLACK_COLOR;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 20px;
        margin-bottom: 40px;
        @include media(tb) {
            padding-bottom: 20px;
            margin-bottom: 30px;
        }
        @include media(sp) {
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
        .news-title{
            font-size: 3.0rem;
            width: 100%;
            line-height: 1.4;
            margin-bottom: 10px;
            @include media(tb) {
                font-size: 2.4rem;
            }
            @include media(sp) {
                font-size: 1.8rem;
                margin-bottom: 5px;
            }
        }
        .news-date{
            font-size: 1.4rem;
            @include media(sp) {
                font-size: 1.2rem;
            }
        }
        .news-shop{
            font-size: 1.4rem;
            margin-left: 20px;
            @include media(sp) {
                font-size: 1.2rem;
                margin-left: 10px;
            }
        }
    }
    .news-body{
        p{
            margin-bottom: 20px;
        }
        img{
            height: auto;
        }
    }
    .news-footer{
        margin-top: 60px;
        @include media(tb) {
            margin-top: 40px;
        }
        @include media(sp) {
            margin-top: 20px;
        }
        a.btn-black{
            margin: 0 auto;
        }
    }
}

.sec-shop-overview{
    background: $BEIGE_COLOR2;
    padding: 100px 0;
    @include media(wide) {
        padding: 90px 0;
    }
    @include media(hd) {
        padding: 80px 0;
    }
    @include media(tb) {
        padding: 60px 0;
    }
    @include media(sp) {
        padding: 40px 0;
    }
    .shop-overview-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60px;
        @include media(tb) {
            margin-bottom: 40px;
        }
        @include media(sp) {
            margin-bottom: 20px;
        }
        &:last-child{
            margin-bottom: 0px;
        }
        .shop-image{
            width: calc(50% - 40px);
            height: 400px;
            border-radius: 30px;
            overflow: hidden;
            @include media(tb) {
                border-radius: 20px;
                width: calc(50% - 20px);
                height: 300px;
            }
            @include media(sp) {
                border-radius: 10px;
                width: 100%;
                height: 60vw;
            }
        }
        .shop-text{
            width: calc(50% - 40px);
            @include media(tb) {
                width: calc(50% - 20px);
            }
            @include media(sp) {
                width: 100%;
                margin-top: 20px;
            }
            .shop-name{
                line-height: 1.4;
                .main{
                    font-size: 3.0rem;
                    @include media(tb) {
                        font-size: 2.4rem;
                    }
                    @include media(tb) {
                        font-size: 1.8rem;
                    }
                }
                .sub{
                    font-size: 2.0rem;
                    @include media(tb) {
                        font-size: 1.6rem;
                    }
                    @include media(sp) {
                        font-size: 1.2rem;
                    }
                }
            }
            .shop-address{
                margin-top: 10px;
            }
            .shop-link{
                margin-top: 20px;
                .btn-black{
                    margin-top: 10px;
                    @include media(sp) {
                        margin: 10px auto 0;
                    }
                }
            }
        }
    }
}
.share-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    @include media(tb) {
        margin-top: 30px;
    }
    @include media(sp) {
        margin-top: 20px;
    }
    .share-message{
        margin-right: 10px;
        font-size: 2.0rem;
        font-weight: bold;
        line-height: 1;
        @include media(tb) {
            font-size: 1.6rem;
        }
        @include media(sp) {
            font-size: 1.4rem;
        }
    }
    .share-list{
        display: flex;
        align-items: center;
    }
    .share-item{
        margin: 0 10px;
        a{
            line-height: 1;
            font-size: 3.4rem;
            color: $BLACK_COLOR;
            @include media(tb) {
                font-size: 3.2rem;
            }
            @include media(sp) {
                font-size: 3.0rem;
            }
            &:after{
                display: none;
            }
        }
    }
}

.sec-notice{
    margin-bottom: 0px;
    .notice-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .notice-item{
        margin: 10px;
        a{
            line-height: 1.2;
            font-size: 1.4rem;
            max-width: inherit;
            padding: 5px 40px 5px 20px;
            @include media(tb){
                font-size: 1.3rem;
            }
            @include media(sp){
                font-size: 1.2rem;
            }
        }
    }
}
