// -------------------------------------------------------------------
//  common.scss
// -------------------------------------------------------------------

// ----------------------------------
//  basic setting
// ----------------------------------
*{
    box-sizing: border-box;
}
html{
    font-size: 62.5%;
}
body{
    overflow-x: hidden;
    @include fontsize($BASE_FONT_SIZE);
    @include media(wide) {
        @include fontsize($BASE_FONT_SIZE_WIDE);
    }
    @include media(hd) {
        @include fontsize($BASE_FONT_SIZE_HD);
    }
    @include media(tb) {
        @include fontsize($BASE_FONT_SIZE_TB);
    }
    @include media(sp) {
        @include fontsize($BASE_FONT_SIZE_SP);
    }
}
html,body{
    //height: 100%;
    background: $WHITE_COLOR;
    color: $BASE_TEXT_COLOR;
    font-family: $NOTO_SANS_JP;
    font-weight: 400;
    line-height: $BASE_LINE_HEIGHT;
    -webkit-text-size-adjust: 100%;
    overflow-x: hidden;
}
.en{
    font-family: $CENTURY_GOTHIC;
}
li{
    list-style: none;
}
img{
    max-width: 100%;
    border:none;
    vertical-align:bottom;
    &.cover{
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
    }
    &.contain{
        width: 100%;
        height: 100%;
        object-fit: contain;
        font-family: 'object-fit: contain;';
    }
}
input,
textarea,
button,
select{
    appearance: none;
}
main{
    display: block;
}
// ----------------------------------
//  web font
// ----------------------------------
.open-sans{
    font-family: $OPEN_SANS;
}
.century-gothic {
    font-family: $CENTURY_GOTHIC;
}

// ----------------------------------
//  link
// ----------------------------------
a{
    transition: opacity .5s ease;
    text-decoration: none;
    color: $LINK_COLOR;
    // PC -----------------
   // @include media(pc){
        &:hover{
            color: $LINK_HOVER_COLOR;
            opacity: 0.6;
        }
        &:visited{
            color: $LINK_VISITED_COLOR;
        }
        &:active{
            color: $LINK_ACTIVE_COLOR;
        }
    //}
}
a[target="_blank"]{
    &:after{
/*
        content: "\f08e";
        font-family: $FONT_AWESOME;
        display: inline-block;
        margin-left: 5px;
*/
        width: 16px;
        height: 16px;
        content: "";
        display: inline-block;
        background: url(../images/common/ico_window_bk.svg) no-repeat center center;
        background-size: contain;
        margin-left: 5px;
        vertical-align: middle;
    }
}
a.btn-black[target="_blank"]{
    &:after{
        width: 20px;
        height: 20px;
        background: url(../images/common/ico_window_wh.svg) no-repeat center center;
        background-size: contain;
    }
    &:hover:after{
        background: url(../images/common/ico_window_wh.svg) no-repeat center center;
    }
}
a.btn-black.is-disabled[target="_blank"]{
    &:after{
        width: 20px;
        height: 20px;
        background: url(../images/common/ico_window_gray.svg) no-repeat center center;
        background-size: contain;
    }
}

// ----------------------------------
//  hover transition
// ----------------------------------
.over{
    transition: opacity .5s ease;
    // PC -----------------
    @include media(pc){
        &:hover{
            opacity: 0.6;
        }
    }
}

// ----------------------------------
//  hover transition
// ----------------------------------
img.zoom{
    transition: transform .5s ease;
    // PC -----------------
    @include media(pc){
        &:hover{
            transform: scale(1.1);
        }
    }
}


// ----------------------------------
//  clearfix
// ----------------------------------
.cf{
    zoom: 1;
    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

// ----------------------------------
//  inner
// ----------------------------------
.inner{
    position: relative;
    max-width: $SITE_WIDTH;
    &.inner_wide{
        max-width: inherit;
    }
    margin: 0 auto;
    padding: 0 120px;

    @include media(wide){
        width: 100%;
        padding: 0 100px;
    }

    @include media(hd){
        width: 100%;
        padding: 0 80px;
    }

    // TB -----------------
    @include media(tb){
        width: 100%;
        padding: 0 40px;
    }

    // SP -----------------
    @include media(sp){
        width: 100%;
        padding: 0 20px;
    }
}

// ----------------------------------
//  scrollbar
// ----------------------------------
.scroll::-webkit-scrollbar{
    width: 5px;
}
.scroll::-webkit-scrollbar-track{
    background: $LIGHT_GRAY_COLOR;
}
.scroll::-webkit-scrollbar-thumb{
    background: $GRAY_COLOR;
    border-radius: 10px;
    box-shadow: inset 0 0 0 2px $GRAY_COLOR;
}

// ----------------------------------
//  responsive(画面幅に応じて対応する要素がblockになる)
// ----------------------------------
// PC -----------------
@include media(pc){
    .is-pc{
        display: block !important;
    }
    .is-tb{
        display: none !important;
    }
    .is-pc-tb{
        display: block !important;
    }
    .is-sp{
        display: none !important;
    }
    .is-tb-sp{
        display: none !important;
    }
}
// TB -----------------
@include media(tb){
    .is-pc{
        display: none !important;
    }
    .is-tb{
        display: block !important;
    }
    .is-pc-tb{
        display: block !important;
    }
    .is-sp{
        display: none !important;
    }
    .is-tb-sp{
        display: block !important;
    }
}
// SP -----------------
@include media(sp){
    .is-pc{
        display: none !important;
    }
    .is-tb{
        display: none !important;
    }
    .is-pc-tb{
        display: none !important;
    }
    .is-sp{
        display: block !important;
    }
    .is-tb-sp{
        display: block !important;
    }
}
.is-hidden{
    display: none;
}







.top .loading{
    display: none;
}
.loading{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: $WHITE_COLOR;
    z-index: 101;
    .logo{
        z-index: 9001;
        width: 100%;
        opacity: 1;
        position: absolute;
        top: calc(50% - 80px);
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: center;
        text-align: center;
        img{
            width: 190px;
            @include media(sp){
                width: 170px;
            }
        }
        span{
            display: block;
            color: $WHITE_COLOR;
            font-size: 1.8rem;
            margin-top: 5px;
            @include media(sp){
                font-size: 1.6rem;
            }
        }
    }
    .loading-txt{
        position: absolute;
        top: calc(50% + 30px);
        top: calc(50% + 0px);
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: center;
        text-align: center;
        color: $BLACK_COLOR;
        font-size: 1.2rem;
        animation: blinking 1.5s infinite linear forwards reverse;
        font-family: $OPEN_SANS;
    }
    .animation{
        display: none;
        margin: 0;
        position: absolute;
        top: calc(50% + 30px);
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: center;
        z-index: 9000;
        width: 80px;
        height: 80px;

        border-radius: 50%;
        border-width: 2px;
        border-style: solid;
        border-color: #9fb6ce rgba(0, 0, 0, 0.12) rgba(0, 0, 0, 0.12);
        animation: spinner 1.5s infinite linear forwards;
    }

}


/* ローディング要素のアニメーション内容 */
@keyframes spinner {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
/* Loading テキストのアニメーション内容 */
@keyframes blinking {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
/*画面遷移アニメーション*/
body.top{
//    background: $NAVY_COLOR;
//    &.appeared{
//        background: $WHITE_COLOR;
//    }
}
.splashbg{
    display: none;
}
/*bodyにappearクラスがついたら出現*/
body.appear .splashbg{
    display: block;
    content: "";
    position:fixed;
    z-index: 999;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    transform: scaleY(0);
    background-color: $GRAY_COLOR;/*伸びる背景色の設定*/
    animation-name:PageAnime;
    animation-duration: 1.8s;
    animation-timing-function:ease-in-out;
    animation-fill-mode:forwards;
}

@keyframes PageAnime{
  0% {
    transform-origin:left;
    transform:scaleX(0);
  }
  50% {
    transform-origin:left;
    transform:scaleX(1);
  }
  50.001% {
    transform-origin:right;
  }
  100% {
    transform-origin:right;
    transform:scaleX(0);
  }
}
/*画面遷移の後現れるコンテンツ設定*/
.top .page-wrapper{
//  opacity: 0;
}

/*bodyにappearクラスがついたら出現*/
body.appear .page-wrapper{
  animation-name:PageAnimeAppear;
  animation-duration:1s;
  animation-delay: 0.8s;
  animation-fill-mode:forwards;
  opacity: 0;
}

@keyframes PageAnimeAppear{
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
