.breadcrumb{
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    @include media(wide) {
    }
    @include media(hd) {
    }
    @include media(tb) {
        padding-bottom: 20px;
    }
    @include media(sp) {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
    }
    ul{
        max-width: 1360px;
        margin: 0 auto;
        padding-top: 10px;
        padding-bottom: 10px;
        //border-bottom: 1px dotted $GRAY_COLOR;
        display: flex;
        justify-content: flex-start;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: auto;
        @include media(tb) {
            justify-content: flex-start;
        }
    }
    li{
        @include fontsize_small;
        &:after{
            content: ">";
            display: inline-block;
            margin: 0 10px;
        }
        &:last-child{
            &:after{
                display: none;
            }
        }
    }
    a{
        text-decoration: underline;
        color: $BLACK_COLOR;
    }
}
