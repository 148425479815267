// -------------------------------------------------------------------
//  mediaquery.scss
// -------------------------------------------------------------------

@mixin media($mediaSize) {
  @if $mediaSize == narrow {
    // SP用(狭)
    @media (max-width: $BREAK_POINT_SP_1 - 1) {
      @content;
    }
  } @else if $mediaSize == sp {
    // SP用
    @media (max-width: $BREAK_POINT_SP_2 - 1) {
      @content;
    }
  } @else if $mediaSize == tb {
    // タブレット用
    @media (max-width: $BREAK_POINT_TB - 1) {
      @content;
    }
  } @else if $mediaSize == hd {
    // PC用(狭)
    @media (max-width: $BREAK_POINT_PC_1 - 1) {
      @content;
    }
  } @else if $mediaSize == wide {
    // PC用(普通)
    @media (max-width: $BREAK_POINT_PC_2 - 1) {
      @content;
    }
  } @else if $mediaSize == pc {
    // PC用全て
    @media (min-width: $BREAK_POINT_TB) {
      @content;
    }
  }
}

@mixin mq($min-width, $max-width) {
  @media (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}

@mixin mq-min($min-width) {
  @media (min-width: $min-width) {
    @content;
  }
}

@mixin mq-max($max-width) {
  @media (max-width: $max-width) {
    @content;
  }
}