/*==================================
fadeIn
===================================*/
.FadeInAnime{
    opacity: 0;
}
.fadeIn{
    animation-name:fadeInAnime;
    animation-duration:1.5s;
    animation-fill-mode:forwards;
    opacity:0;
}
@keyframes fadeInAnime{
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/*==================================
fadeInUp
===================================*/
.FadeInUpAnime{
    opacity: 0;
}
.fadeInUp{
    animation-name:fadeInUpAnime;
    animation-duration:1.0s;
    animation-fill-mode:forwards;
    opacity:0;
}
@keyframes fadeInUpAnime{
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/*==================================
fadeInLeft
===================================*/
.FadeInLeftAnime{
    opacity: 0;
}
.fadeInLeft{
    animation-name:fadeInLeftAnime;
    animation-duration:1.0s;
    animation-fill-mode:forwards;
    opacity:0;
}
@keyframes fadeInLeftAnime{
    from {
        opacity: 0;
        transform: translateX(-20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

