// -------------------------------------------------------------------
//  shops.scss
// -------------------------------------------------------------------
.shops{
    .sec-shops{
        .shop-search{
            margin-bottom: 40px;
            .shop-search-ttl{
                text-align: center;
                font-size: 4.0rem;
                margin-bottom: 20px;
                font-weight: 100;
                display: none;
            }
            .panel-item{
                .category-container{
                    border: 1px solid $BORDER_COLOR;
                    border-top: none;
                    padding: 20px 40px;
                }
                dl{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-bottom: 20px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                dt{
                    width: 150px;
                    font-weight: bold;
                    font-size: 1.4rem;
                    @include media(sp){
                        width: 100%;
                    }
                }
                dd{
                    width: calc(100% - 150px);
                    @include media(sp){
                        width: 100%;
                    }
                }
            }
            .select-item{
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .shop-container{
            .shop-list{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                &:before{
                    content:"";
                    display: block;
                    width: calc(25% - 30px);
                    order:1;
                    @include media(hd){
                        width: calc(33.33% - 20px);
                    }
                    @include media(sp){
                        width: calc(50% - 10px);
                    }
                }
                &:after{
                    content:"";
                    display: block;
                    width: calc(25% - 30px);
                    @include media(hd){
                        width: calc(33.33% - 20px);
                    }
                    @include media(sp){
                        width: calc(50% - 10px);
                    }
                }
            }
            .shop-item{
                width: calc(25% - 20px);
                margin-bottom: 40px;
                @include media(hd){
                    width: calc(33.33% - 20px);
                }
                @include media(sp){
                    width: calc(50% - 10px);
                }
                a{
                    color: $BLACK_COLOR;
                }
                .shop-image{
                    height: 240px;
                    border-radius: 20px;
                    overflow: hidden;
                    @include media(sp){
                        border-radius: 10px;
                        height: 120px;
                    }
                }
                .shop-icon{
                    margin-top: 5px;
                    span{
                        display: inline-block;
                        background: $BLACK_COLOR;
                        color: $WHITE_COLOR;
                        font-size: 1.2rem;
                        border-radius: 5px;
                        margin-right: 5px;
                        line-height: 1;
                        padding: 6px 10px;
                        @include media(sp){
                            padding: 4px 10px;
                            font-size: 1.0rem;
                        }
                    }
                }
                .shop-name{
                    font-size: 1.6rem;
                    line-height: 1.4;
                    margin-top: 5px;
                    @include media(sp){
                        font-size: 1.4rem;
                    }
                }
                .shop-comment{
                    font-size: 1.2rem;
                    color: $RED_COLOR;
                    line-height: 1.4;
                    @include media(sp){
                        font-size: 1.0rem;
                    }
                }
                .shop-url{
                    font-size: 1.2rem;
                    line-height: 1.4;
                    @include media(sp){
                        font-size: 1.0rem;
                    }
                    a{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
