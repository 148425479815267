// -------------------------------------------------------------------
//  story-detail.scss
// -------------------------------------------------------------------
.story-detail{
    background-color: $BEIGE_COLOR1;
    .page-title-text-image{
        .page-image{
            position: relative;
            .story-text{
                text-align: center;
                background: rgba(0,0,0,0.3);
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 60px;
                color: $WHITE_COLOR;
                @include media(sp){
                    padding: 40px;
                }
                .story-time{
                    line-height: 1.4;
                    display: block;
                    font-size: 1.4rem;
                    @include media(tb){
                        font-size: 1.2rem;
                    }
                }
                .story-title{
                    line-height: 1.4;
                    font-size: 2.4rem;
                    @include media(tb){
                        font-size: 2.0rem;
                    }
                    @include media(sp){
                        font-size: 1.6rem;
                    }
                }
                .story-category{
                    margin-top: 10px;
                    span{
                        display: inline-block;
                        border: 1px solid $WHITE_COLOR;
                        padding: 5px 10px;
                        font-size: 1.2rem;
                        @include media(tb){
                            padding: 2px 4px;
                            font-size: 1.0rem;
                        }
                        @include media(sp){
                            font-size: 1.0rem;
                        }
                    }
                }
            }
        }
    }
    .story-lead{
        max-width: 1200px;
        margin: 0 auto;
        margin-bottom: 60px;
        @include media(tb){
            margin-bottom: 40px;
        }
        @include media(sp){
            margin-bottom: 20px;
        }
    }
    .story-body{
        max-width: 1360px;
        margin: 0 auto;
        background: $WHITE_COLOR;
        padding: 40px 80px;
        border-radius: 40px;
        @include media(wide){
            padding: 40px 60px;
        }
        @include media(hd){
            padding: 40px 40px;
        }
        @include media(tb){
            padding: 30px;
            border-radius: 30px;
        }
        @include media(sp){
            padding: 20px;
            border-radius: 20px;
        }
        h3{
            line-height: 1.4;
            font-size: 2.0rem;
            border-bottom: 1px solid $BLACK_COLOR;
            margin-bottom: 20px;
            padding-bottom: 10px;
            @include media(tb){
                font-size: 1.8rem;
                margin-bottom: 10px;
            }
            @include media(sp){
                font-size: 1.6rem;
            }
        }
        h4{
            line-height: 1.4;
            font-size: 1.8rem;
            border-bottom: 1px dotted $BLACK_COLOR;
            margin-bottom: 20px;
            padding-bottom: 10px;
            @include media(tb){
                font-size: 1.6rem;
                margin-bottom: 10px;
            }
            @include media(sp){
                font-size: 1.4rem;
            }
        }
        p{
            margin-bottom: 40px;
            @include media(tb){
                margin-bottom: 30px;
            }
            @include media(sp){
                margin-bottom: 20px;
            }
        }
        ul{
            list-style: none;
            margin-bottom: 40;
            @include media(tb){
                margin-bottom: 30px;
            }
            @include media(sp){
                margin-bottom: 20px;
            }
        }
        img{
            max-width: 100%;
            height: auto;
        }
    }
    .story-tag{
        .tag-list{
            display: flex;
            justify-content: center;
            margin-top: 40px;
            @include media(tb) {
                margin-top: 30px;
            }
            @include media(sp) {
                margin-top: 20px;
            }
        }
        .tag-item{
            margin: 0 5px;
            a{
                display: block;
                background: $BLACK_COLOR;
                color: $WHITE_COLOR;
                padding: 2px 20px;
                line-height: 1.4;
            }
        }
    }
    .story-writer{
        max-width: 1360px;
        margin: 0 auto;
        background: $WHITE_COLOR;
        padding: 40px;
        border-radius: 40px;
        margin-top: 40px;
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @include media(tb){
            padding: 30px;
            border-radius: 30px;
            margin-top: 30px;
            margin-bottom: 30px;
        }
        @include media(sp){
            padding: 20px;
            border-radius: 20px;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .story-writer-image{
            width: 200px;
            height: 200px;
            @include media(sp){
                width: 100%;
                text-align: center;
                img{
                    width: 200px;
                }
            }
        }
        .story-writer-text{
            width: calc(100% - 200px);
            padding-left: 40px;
            @include media(tb){
                padding-left: 30px;
            }
            @include media(sp){
                width: 100%;
                padding-left: 0px;
                margin-top: 20px;
            }
            .writer-title{
                font-size: 1.6rem;
                @include media(sp){
                    font-size: 1.4rem;
                }
            }
            p.writer-description{
                font-size: 1.4rem;
                @include media(sp){
                    font-size: 1.2rem;
                }
            }
        }
    }
    .story-footer{
        margin-top: 60px;
        @include media(tb) {
            margin-top: 40px;
        }
        @include media(sp) {
            margin-top: 20px;
        }
        a.btn-black{
            margin: 0 auto;
        }
    }

    .story-index{
        margin-top: 60px;
        @include media(tb){
            margin-top: 40px;
        }
        @include media(sp){
            margin-top: 20px;
        }
    }
}
