// -------------------------------------------------------------------
//  mailmagazine.scss
// -------------------------------------------------------------------
.mailmagazine{
    .sec-mailmagazine{
        .mailmagazine-lead{
            //border: 1px solid $BORDER_COLOR;
            margin-bottom: 40px;
            padding: 50px 0px 50px;
            @include media(wide){
                margin-bottom: 35px;
                padding: 45px 0px;
            }
            @include media(hd){
                margin-bottom: 30px;
                padding: 40px 0px;
            }
            @include media(tb){
                margin-bottom: 20px;
                padding: 30px 0px;
            }
            @include media(sp){
                margin-bottom: 10px;
                padding: 20px 0px;
            }
            .mailmagazine-lead-title{
                text-align: center;
                margin-bottom: 40px;
                @include media(tb){
                    margin-bottom: 30px;
                }
                @include media(sp){
                    margin-bottom: 20px;
                }
                .main{
                    line-height: 1.4;
                    font-size: 4.0rem;
                    font-weight: 100;
                    letter-spacing: 0.1em;
                    strong{
                        font-weight: 900;
                    }
                    @include media(sp){
                        font-size: 2.3rem;
                    }
                }
                .sub{
                    line-height: 1.4;
                    font-size: 2.0rem;
                    font-weight: 100;
                    letter-spacing: 0.1em;
                    @include media(sp){
                        font-size: 1.2rem;
                    }
                }
            }
            .mailmagazine-text{
                text-align: center;
                margin-bottom: 40px;
                @include media(tb){
                    margin-bottom: 30px;
                }
                @include media(sp){
                    margin-bottom: 20px;
                }
            }
            .mailmagazine-image{
                max-width: 1000px;
                height: 400px;
                margin: 0 auto;
                border-radius: 40px;
                overflow: hidden;
                @include media(tb){
                    height: 300px;
                    border-radius: 30px;
                }
                @include media(sp){
                    height: 200px;
                    border-radius: 20px;
                }
            }
        }
        .mailmagazine-register{
            max-width: 1000px;
            margin: 0 auto;
            .mailmagazine-register-container{
                background: $BEIGE_COLOR1;
                padding: 40px;
                @include media(tb){
                    padding: 30px;
                }
                @include media(sp){
                    padding: 20px;
                }
            }
            .mailmagazine-register-title{
                font-size: 2.0rem;
                text-align: center;
                margin-bottom: 10px;
                @include media(tb){
                    font-size: 1.8rem;
                }
                @include media(sp){
                    font-size: 1.6rem;
                }
            }
            .mailmagazine-register-text{
                margin-bottom: 20px;
                a{
                    color: $BLACK_COLOR;
                    text-decoration: underline;
                }
            }
            .mailmagazine-register-button{
                @include flex_inner_center;
            }
            .mailmagazine-register-link{
                display: flex;
                justify-content: flex-end;
                margin-top: 20px;
                @include media(sp){
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    margin-top: 10px;
                }
                p{
                    margin-left: 40px;
                    @include media(sp){
                        margin-left: 0px;
                        margin-top: 10px;
                    }
                }
                a{
                    color: $BLACK_COLOR;
                    text-decoration: underline;
                }
            }
        }
    }
}