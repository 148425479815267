// ----------------------------------
//  form
// ----------------------------------
.validation-form{
    //max-width: 600px;
    //margin: 0 auto;
}
.form-list{
    display: flex;
    flex-wrap: wrap;
    dt{
        color: $BLACK_COLOR;
        position: relative;
        width: 30%;
        padding: 20px 20px;
        font-weight: bold;
        @include media(sp){
            width: 100%;
            padding: 10px 0px 5px;
        }
        .ico-required:after{
            content: "※必須";
            @include fontsize_small;
            display: inline-block;
            color: $RED_COLOR;
            margin-left: 10px;
        }
    }
    dd{
        width: 70%;
        padding: 20px 20px;
        @include media(sp){
            width: 100%;
            padding: 0 0 10px;
        }
        label.error{
            color: $RED_COLOR;
        }
    }
    input[type=text],
    input[type=email],
    select{
        outline: 0;
        width: 100%;
        padding: 10px;
        border: 1px solid $LIGHT_GRAY_COLOR;
        background: $WHITE_COLOR;

        &:focus{
            border-color: $GRAY_COLOR;
        }

        &.zip1{
            width: 45px;
            margin-bottom: 10px;
        }
        &.zip2{
            width: 60px;
            margin-bottom: 10px;
        }
    }
    textarea{
        outline: 0;
        width: 100%;
        height: 160px;
        padding: 1.0rem;
        border: 1px solid $LIGHT_GRAY_COLOR;

        &:focus{
            border-color: $GRAY_COLOR;
        }
    }
}

// ----------------------------------
//  .label-checkbox
// ----------------------------------
.label-checkbox{
    cursor: pointer;
    position: relative;
    display: inline-block;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    @include media(sp){
        margin-right: 5px;
    }

    input[type="checkbox"]{
        display: none;

        &:checked{
            + .item-name{
                color: $WHITE_COLOR;
                border: 1px solid $BLACK_COLOR;
                background-color: $BLACK_COLOR;
            }
        }
    }

    .item-name{
        font-size: 1.4rem;
        display: block;
        position: relative;
        padding: 5px 20px;
        transition: all 0.2s linear;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        border: 1px solid $OFF_WHITE_COLOR;
        background-color: $OFF_WHITE_COLOR;
        @include media(sp){
            font-size: 1.2rem;
            padding: 2px 10px;
            border-radius: 10px;
        }
    }
}


// ----------------------------------
//  .label-radio
// ----------------------------------
.label-radio{
    cursor: pointer;
    position: relative;
    display: inline-block;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    @include media(sp){
        margin-right: 5px;
    }

    input[type="radio"]{
        display: none;

        &:checked{
            + .item-name{
                color: $WHITE_COLOR;
                border: 1px solid $BLACK_COLOR;
                background-color: $BLACK_COLOR;
            }
        }
    }

    .item-name{
        font-size: 1.4rem;
        display: block;
        position: relative;
        padding: 5px 20px;
        transition: all 0.2s linear;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        border: 1px solid $OFF_WHITE_COLOR;
        background-color: $OFF_WHITE_COLOR;
        @include media(sp){
            font-size: 1.2rem;
            padding: 2px 10px;
            border-radius: 10px;
        }
    }
}


// ----------------------------------
//  .select-wrap
// ----------------------------------
.select-wrap{
    position: relative;

    &:after{
        color: $WHITE_COLOR;
        font-family: $FONT_AWESOME;
        content: "\f078";
        font-size: 1.4rem;
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        pointer-events: none;
        @include flex_inner_center;
    }

    select{
        display: block;
        width: 100%;
        cursor: pointer;
        text-overflow: ellipsis;
        padding: 10px 15px 10px 10px;
        background: $DARK_GRAY_COLOR;
        color: $WHITE_COLOR;
        border-radius: 10px;
        border: 1px solid $LIGHT_GRAY_COLOR;
        &::-ms-expand {
            display: none;
        }
    }
}

