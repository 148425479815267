// -------------------------------------------------------------------
//  mixin.scss
// -------------------------------------------------------------------

// font-size-rem
// -------------------------------------------------------------------
@mixin fontsize($size) {
  font-size: ($size / 10) * 1rem;
  font-size: $size * 1px;
}

// fontsize_huge
// -------------------------------------------------------------------
@mixin fontsize_huge {
    font-size: 4.0rem;
    @include media(wide) {
        font-size: 3.8rem;
    }
    @include media(hd) {
        font-size: 3.6rem;
    }
    @include media(tb) {
        font-size: 2.0rem;
    }
    @include media(sp) {
        font-size: 1.8rem;
    }
}

// fontsize_large
// -------------------------------------------------------------------
@mixin fontsize_large {
    font-size: 3.0rem;
    @include media(wide) {
        font-size: 2.8rem;
    }
    @include media(hd) {
        font-size: 2.6rem;
    }
    @include media(tb) {
        font-size: 1.8rem;
    }
    @include media(sp) {
        font-size: 1.6rem;
    }
}

// fontsize_small
// -------------------------------------------------------------------
@mixin fontsize_small {
    font-size: 1.4rem;
    @include media(wide) {
        font-size: 1.4rem;
    }
    @include media(hd) {
        font-size: 1.4rem;
    }
    @include media(tb) {
        font-size: 1.2rem;
    }
    @include media(sp) {
        font-size: 1.0rem;
    }
}

// fontsize_normal
// -------------------------------------------------------------------
@mixin fontsize_normal {
    @include fontsize($BASE_FONT_SIZE);
    @include media(wide) {
        @include fontsize($BASE_FONT_SIZE_WIDE);
    }
    @include media(hd) {
        @include fontsize($BASE_FONT_SIZE_HD);
    }
    @include media(tb) {
        @include fontsize($BASE_FONT_SIZE_TB);
    }
    @include media(sp) {
        @include fontsize($BASE_FONT_SIZE_SP);
    }
}

// flex_inner_center
// -------------------------------------------------------------------
@mixin flex_inner_center{
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
  transition: all 0.2s linear;
}


// flex_space_between
// -------------------------------------------------------------------
@mixin flex_space_between{
  display: flex;
  justify-content: space-between;
  //align-items: center;
  flex-wrap: wrap;
}
