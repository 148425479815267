// -------------------------------------------------------------------
//  project.scss
// -------------------------------------------------------------------
.project{
    .sec-mv{
        position: relative;
        width: 100%;
        height: 60vh;
        min-height: 300px;
        max-height: 600px;
        overflow: hidden;
        @include media(tb) {
            height: 80vw;
            max-height: 400px;
        }
        .mv-image{
            position: relative;
            width: 100%;
            height: 100%;
            &:before{
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.4);
                transition: background 0.5s ease;
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
        .page-title{
            position: absolute;
            top: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            @include flex_inner_center;
            color: $WHITE_COLOR;
            font-size: 6.0rem;
            font-weight: 100;
            text-align: center;
            line-height: 1.2;
            @include media(tb) {
                font-size: 5.0rem;
            }
            @include media(sp) {
                font-size: 3.0rem;
            }
        }
    }
}