.pagination-container{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    @include media(tb){
        margin-top: 30px;
    }
    @include media(sp){
        margin-top: 20px;
    }
    .pagination{
        display: flex;
        justify-content: center;
        li{
            font-family: $OPEN_SANS;
            margin: 0 5px;
            a{
                display: block;
                color: $BLACK_COLOR;
                width: 30px;
                height: 30px;
                @include flex_inner_center;
                @include media(sp){
                    width: 20px;
                    height: 20px;
                }
            }
            &.prev{
                margin-right: 40px;
                @include media(sp){
                    margin-right: 20px;
                }
            }
            &.next{
                margin-left: 40px;
                @include media(sp){
                    margin-left: 20px;
                }
            }
            &.active{
                a{
                    background: $BLACK_COLOR;
                    color: $WHITE_COLOR;
                    border-radius: 50%;
                }
            }
            &.disabled{
                opacity: 0.3;
            }
        }
    }
}
