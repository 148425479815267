// -------------------------------------------------------------------
//  top.scss
// -------------------------------------------------------------------
.top{
    .main{
        padding-top: $HEADER_HEIGHT_PC;
        @include media(wide) {
        }
        @include media(hd) {
        }
        @include media(tb) {
            padding-top: $HEADER_HEIGHT_TB;
        }
        @include media(sp) {
            padding-top: $HEADER_HEIGHT_SP;
        }
        @include media(narrow) {
        }
    }

    // ----------------------------------
    //  .sec-slide
    // ----------------------------------
    .sec-slide{
        position: relative;
        z-index: 2;
        margin-bottom: 40px;
        @include media(tb){
            margin-bottom: 30px;
        }
        @include media(sp){
            margin-bottom: 20px;
        }
        .swiper-slide{
            background: transparent;
            border-radius: 40px;
            overflow: hidden;
            position: relative;
            z-index: 1;
            @include media(tb) {
                border-radius: 0px;
            }
            a{
                display: block;
                height: 100%;
                width: 100%;
                &:after{
                    display: none;
                }
            }
            .image{
                height: 60vw;
                max-height: 600px;
                overflow: hidden;
                @include flex_inner_center;
                @include media(tb){
                    height: 60vw;
                    max-height: inherit;
                }
                @include media(sp){
                    height: 60vh;
                    max-height: inherit;
                }
            }
        }
    }


    // ----------------------------------
    //  .sec-lead
    // ----------------------------------
    .sec-lead{
        margin-top: 0px;
        padding-top: 60px;
        @include media(tb){
            margin-top: 0px;
            padding-top: 40px;
        }
        @include media(sp){
            margin-top: 0px;
            padding-top: 30px;
        }
        .lead-text{
            font-size: 4.2rem;
            line-height: 1.4;
            @include media(hd){
                font-size: 4.0rem;
            }
            @include media(tb){
                font-size: 3.6rem;
            }
            @include media(sp){
                font-size: 1.8rem;
            }
            @include media(narrow){
                font-size: 1.4rem;
            }
        }
    }

    // ----------------------------------
    //  .sec-topics
    // ----------------------------------
    .sec-topics{
        .topics-container{
            @include flex_space_between;
            align-items: center;
            position: relative;
            @include media(sp){
                margin-right: -20px;
                margin-left: -20px;
            }
            .title-container{
                width: 220px;
                @include media(wide){
                    width: 170px;
                }
                @include media(hd){
                    width: 120px;
                }
                @include media(tb){
                    width: 100%;
                }
                .sec-title{
                    margin-bottom: 0;
                    @include media(tb){
                        margin-bottom: 30px;
                    }
                    padding-bottom: 0;
                    &:after{
                        display: none;
                    }
                }
            }
            .content-container{
                width: calc(100% - 300px);
                position: relative;
                @include media(wide){
                    width: calc(100% - 250px);
                }
                @include media(hd){
                    width: calc(100% - 200px);
                }
                @include media(tb){
                    width: 100%;
                }
                .swiper-container{
                }
                .swiper-slide{
                    a{
                        color: $BLACK_COLOR;
                        &:after{
                            display: none;
                        }
                    }
                    .topics-image{
                        height: 15vw;
                        max-height: 300px;
                        border-radius: 30px;
                        overflow: hidden;
                        position: relative;
                        z-index: 1;
                        @include media(hd){
                            height: 18vw;
                            max-height: inherit;
                        }
                        @include media(tb){
                            height: 24vw;
                            max-height: inherit;
                        }
                        @include media(sp){
                            height: 200px;
                        }
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .topics-category{
                        display: inline-block;
                        text-align: center;
                        margin-top: 10px;
                        font-size: 1.2rem;
                        font-weight: 100;
                        line-height: 1.4;
                        border: 1px solid $BORDER_COLOR;
                        padding: 4px 5px;
                    }
                    .topics-title{
                        margin-top: 10px;
                        font-size: 1.2rem;
                        font-weight: 100;
                        line-height: 1.4;
                    }
                }
                .swiper-button-next,
                .swiper-button-prev{
                }
                .swiper-pagination{
                    display: none;
                }
                @include media(sp){
                    padding-bottom: 60px;
                    .swiper-button-next,
                    .swiper-button-prev{
                        top: auto;
                        bottom: -20px;
                    }
                    .swiper-pagination{
                        display: block;
                        bottom: 15px;
                    }
                }
            }
        }
    }

    // ----------------------------------
    //  .sec-twitter
    // ----------------------------------
    .sec-twitter{
        .twitter-container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            i{
                font-size: 4.0rem;
                width: 40px;
                @include media(sp){
                    font-size: 3.0rem;
                    width: 30px;
                }
            }
            .twitter-text{
                width: calc(100% - 60px);
                @include media(sp){
                    width: calc(100% - 40px);
                }
                a{
                    color: $BLACK_COLOR;
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    border: 1px solid $BLACK_COLOR;
                    border-radius: 30px;
                    padding: 10px 30px;
                    width: 100%;
                    transition: all 0.5s ease;
                    position: relative;
                    z-index: 1;
                    @include media(sp){
                        border-radius: 20px;
                        padding: 5px 10px;
                        font-size: 1.0rem;
                    }
                    &:after{
                        display: none;
                    }
                    &:hover{
                        opacity: 1;
                        background: $BLACK_COLOR;
                        color: $WHITE_COLOR;
                    }
                }
            }
        }
    }

    // ----------------------------------
    //  .sec-index
    // ----------------------------------
    .sec-index{
        .index-list{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .index-item{
                width: calc(33.33% - 30px);
                margin-bottom: 60px;
                border-radius: 40px;
                overflow: hidden;
                position: relative;
                z-index: 1;
                &:nth-child(1),
                &:nth-child(2){
                    width: calc(50% - 30px);
                    @include media(hd){
                        width: calc(50% - 20px);
                    }
                    @include media(sp){
                        width: 100%;
                    }
                    &:before{
                        content: "";
                        display: block;
                        padding-top: 60%;
                        @include media(sp){
                            padding-top: 60%;
                        }
                    }
                }
                &:before{
                    content: "";
                    display: block;
                    padding-top: 80%;
                    @include media(sp){
                        padding-top: 60%;
                    }
                }
                @include media(hd){
                    width: calc(33.33% - 20px);
                    margin-bottom: 45px;
                    border-radius: 30px;
                }
                @include media(tb){
                    width: calc(33.33% - 20px);
                    margin-bottom: 30px;
                    border-radius: 20px;
                }
                @include media(sp){
                    width: 100%;
                    margin-bottom: 20px;
                    border-radius: 10px;
                }
                a{
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    color: $WHITE_COLOR;
                    @include media(pc){
                        &:hover{
                            opacity: 1;
                            .index-image{
                                &:before{
                                    background: rgba(0,0,0,0.8);
                                }
                                img{
                                    transform: scale(1.1);
                                }
                            }
                        }
                    }
                }
                .index-image{
                    position: relative;
                    height: 100%;
                    &:before{
                        content: "";
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        background: rgba(0,0,0,0.2);
                        transition: background 0.5s ease;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        transition: all 0.5s ease;
                    }
                }
                .index-text{
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding: 40px;
                    @include media(tb){
                        padding: 20px;
                    }
                    @include flex_inner_center;
                    .index-title{
                        font-weight: 400;
                        width: 100%;
                        text-align: center;
                        span{
                            display: block;
                        }
                        .en{
                            font-size: 3.6rem;
                            line-height: 1.2;
                            @include media(wide){
                                font-size: 2.8rem;
                            }
                            @include media(hd){
                                font-size: 2.4rem;
                            }
                            @include media(tb){
                                font-size: 2.0rem;
                            }
                            @include media(sp){
                                font-size: 2.4rem;
                            }
                        }
                        .jp{
                            margin-top: 10px;
                            font-size: 1.4rem;
                            line-height: 1.4;
                            @include media(tb){
                                font-size: 1.2rem;
                            }
                        }
                    }
                    .index-description{
                        margin-top: 20px;
                        font-size: 1.2rem;
                        line-height: 1.4;
                        @include media(tb){
                            font-size: 1.0rem;
                            display: none;
                        }
                    }
                }
            }
        }
    }

    // ----------------------------------
    //  .sec-news
    // ----------------------------------
    .sec-news{
        .top-news-container{
            @include flex_space_between;
            align-items: center;
            position: relative;
            @include media(tb){
                padding-bottom: 100px;
            }
            @include media(sp){
                padding-bottom: 60px;
            }
            @include media(sp){
                margin-right: -20px;
                margin-left: -20px;
            }
            .title-container{
                width: 320px;
                @include media(hd){
                    width: 220px;
                }
                @include media(tb){
                    width: 100%;
                }
                .sec-title{
                    margin-bottom: 30px;
                    padding-bottom: 0;
                    &:after{
                        display: none;
                    }
                }
                a{
                    @include media(tb){
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                    }
                    @include media(sp){
                        max-width: 300px;
                    }
                }
            }
            .news-container{
                width: calc(100% - 400px);
                @include media(hd){
                    width: calc(100% - 300px);
                }
                @include media(tb){
                    width: 100%;
                    margin: 0;
                    padding: 0;
                }
                @include media(sp){
                    overflow-x: auto;
                    overflow-y: hidden;
                    padding: 0 20px 20px;
                    display: flex;
                }
                .news-list{
                    @include flex_space_between;
                    flex-wrap: nowrap;
                    &:before{
                        display: none;
                    }
                    &:after{
                        display: none;
                    }
                    @include media(tb){
                        width: auto;
                    }
                }
                .news-item{
                    width: calc(33.33% - 20px);
                    line-height: 1;
                    display: flex;
                    flex-wrap: wrap;
                    @include media(sp){
                        width: 280px;
                        &:not(:last-child){
                            margin-right: 20px;
                        }
                    }
                    .news-image{
                        height: 18vw;
                        max-height: 350px;
                        border-radius: 30px;
                        @include media(tb){
                            height: 24vw;
                            max-height: inherit;
                        }
                        @include media(sp){
                            height: 200px;
                        }
                    }
                    .news-category{
                        font-weight: 100;
                        font-size: 1.2rem;
                        position: absolute;
                        right: 0;
                        top: 0;
                        text-transform: uppercase;
                        line-height: 1.4;
                        @include media(sp){
                            font-size: 1.0rem;
                        }
                    }
                }
            }
        }
    }
}








.TitleTextAnime span{
    opacity: 0;
    display: inline-block;
}
.TitleTextAnime.appeartext span{ animation:text_anime_on_ttl 1s ease-out forwards; }
.CatchTextAnime span{
    opacity: 0;
    display: inline-block;
}
.CatchTextAnime.appeartext span{ animation:catch_anime_on_ttl 1s ease-out forwards; }
@keyframes text_anime_on_ttl {
  0% {
    opacity:0;
    transform: translateX(-10px);
  }
  100% {
    opacity:1;
    transform: translateX(0px);
  }
}
@keyframes catch_anime_on_ttl {
  0% {
    opacity:0;
    transform: translateX(-20px);
  }
  100% {
    opacity:1;
    transform: translateX(0px);
  }
}




