// -------------------------------------------------------------------
//  yearend.scss
// -------------------------------------------------------------------
.yearend{
    @include media(tb){
        .tab-item{
            width: 50%;
        }
    }

    .prefecture-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .prefecture-item{
        margin: 0 10px;
        a{
            display: block;
            color: $BLACK_COLOR;
            border: 1px solid $BORDER_COLOR;
            padding: 5px 20px;
        }
    }
    .sec-sub-content{
        border-bottom: 1px dotted $BORDER_COLOR;
        padding-bottom: 40px;
        margin-bottom: 40px;
        &:last-child{
            border-bottom: none;
            padding-bottom: 0px;
            margin-bottom: 0px;
        }
        .sub-sec-ttl{
            text-align: center;
            font-weight: 100;
            .jp{
                display: block;
                line-height: 1.4;
                font-size: 3.0rem;
                @include media(tb){
                    font-size: 2.4rem;
                }
                @include media(sp){
                    font-size: 1.8rem;
                }
            }
            .en{
                display: block;
                line-height: 1.4;
                font-size: 1.6rem;
                @include media(tb){
                    font-size: 1.4rem;
                }
                @include media(sp){
                    font-size: 1.2rem;
                }
            }
        }
    }
    .days{
        padding-left: 15%;
        @include media(tb){
            padding-left: 12%;
        }
        margin-bottom: 40px;
        @include media(tb){
            margin-bottom: 30px;
        }
        @include media(sp){
            margin-bottom: 20px;
        }
    }
    .shop-list{
    }
    .shop-item{
        a{
            color: $BLACK_COLOR;
            position: relative;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 40px;
            @include media(tb){
                margin-bottom: 30px;
            }
            @include media(sp){
                margin-bottom: 20px;
            }
        }
    }
    .shop-name{
        width: 100%;
        font-weight: 100;
        font-size: 2.0rem;
        margin-bottom: 5px;
        @include media(tb){
            font-size: 1.8rem;
        }
        @include media(sp){
            font-size: 1.6rem;
        }
    }
    .shop-logo{
        width: 11%;
        position: relative;
        border: 1px solid $BORDER_COLOR;
        &:before{
            content: "";
            display: block;
            padding-top: 100%;
        }
        span{
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            @include flex_inner_center;
            padding: 30px;
            @include media(hd){
                padding: 20px;
            }
            @include media(tb){
                padding: 10px;
            }
            @include media(sp){
                padding: 5px;
            }
        }
    }
    .times{
        width: 85%;
        @include media(tb){
            width: 88%;
        }
        &.nodata{
            @include flex_inner_center;
        }
    }
}
