// ----------------------------------
//  drawer_menu
// ----------------------------------
/* ドロワーメニュー */
#drawer_menu{
    width:100%;
    height:100vh;
    z-index:200;
    color:#fff;
    overflow:hidden;
    overflow-x:hidden;
    pointer-events:none;
    position:fixed;
    left:0px;
    top: 0;
    //transition: opacity 0.7s cubic-bezier(0.22, 1, 0.36, 1) 0s;
    //background: $GRAY_COLOR;
    background: transparent;
    display: flex;
    align-items: center;
    #drawer_menu_bg{
        position: fixed;
        top: 10px;
        right: 20px;
        width:56px;
        height:56px;
        min-width: 56px;
        min-height: 56px;
        @include media(tb){
            top: 5px;
        }
        @include media(sp){
            top: -70px;
            right: -70px;
            width:140px;
            height:140px;
        }
        span{
            position: relative;
            display: block;
            width:100%;
            height:100%;
            &:before{
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                transition: all 1.0s ease;
                display:block;
                width:100%;
                height:100%;
                background: $BEIGE_COLOR1;
                //background: red;
                border-radius: 50%;
                opacity: 0;
            }
        }
    }
    #drawer_menu_inner{
        position:relative;
        width:100vw;
        padding-bottom:130px;
        padding: 0px 80px;
        transition: opacity 0.5s ease;
        opacity: 0;
        &.is-show{
            opacity: 1;
        }
        @include media(tb) {
            padding: 0px 40px;
        }
        @include media(sp) {
            padding: 0px;
        }
        .inner{
            padding: 0;
        }

        h2{
            font-family: $CENTURY_GOTHIC;
            font-weight: 400;
            color: $DARK_GRAY_COLOR;
            line-height: 1.2;
            font-size: 1.8rem;
            position: absolute;
            left: -120px;
            top: 50%;
            transform: rotate(90deg);
            transform-origin: center center 0;
            @include media(tb) {
                display: none;
            }
        }
        .inner{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }
        .img-wrapper{
            width: 40%;
            max-width: calc(100% - 780px);
            height: 100vh;
            @include media(tb) {
                display: none;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .menu-wrapper{
            width: 60%;
            min-width: 780px;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            max-height: 100vh;
            overflow-x: hidden;
            overflow-y: auto;
            padding-left: 80px;
            @include media(tb) {
                min-width: inherit;
                max-width: 780px;
                width: 100%;
                padding: 0px;
                margin: 0 auto;
            }
            @include media(sp) {
                padding: 60px 40px;
            }
        }
        .menu-list{
            span{
                line-height: 1;
                display: block;
            }
            a{
                display: block;
                &[target="_blank"]:after{
                    display: none;
                    margin: 0;
                }
            }
        }
        .menu-wrapper-left{
            width: 40%;
            padding-right: 20px;
            @include media(sp) {
                width: 100%;
                padding-right: 0px;
            }
            .menu-item{
                font-size: 2.8rem;
                margin: 10px 0;
                @include media(sp) {
                    font-size: 2.0rem;
                    margin: 10px 0;
                }
                a{
                    color: $BLACK_COLOR;
                    padding: 5px;
                    position: relative;
                    transition: all 0.5s ease;
                    span{
                        position: relative;
                        z-index: 2;
                    }
                    &:after{
                        transition: width 0.5s ease;
                        content: "";
                        display: block;
                        position: absolute;
                        z-index: 1;
                        background: $DARK_GRAY_COLOR;
                        width: 0px;
                        height: 100%;
                        top: 0;
                        left: 0;
                    }
                    &[target="_blank"]{
                        span.en:after{
                            width: 24px;
                            height: 24px;
                            content: "";
                            display: inline-block;
                            background: url(../images/common/ico_window_bk.svg) no-repeat center center;
                            background-size: contain;
                            margin-left: 10px;
                            vertical-align: middle;
                        }
                    }
                    &:hover{
                        color: $WHITE_COLOR;
                        opacity: 1;
                        &:after{
                            width: 100%;
                        }
                        &[target="_blank"]{
                            span.en:after{
                                background: url(../images/common/ico_window_wh.svg) no-repeat center center;
                            }
                        }
                    }
                }
            }
        }
        .menu-wrapper-right{
            width: 60%;
            padding-left: 20px;
            @include media(sp) {
                width: 100%;
                padding-left: 0px;
            }
            .menu-item{
                font-size: 1.8rem;
                margin: 9px 0;
                @include media(sp) {
                    font-size: 1.4rem;
                    margin: 10px 0;
                }
                a{
                    color: $BLACK_COLOR;
                    padding: 5px;
                    position: relative;
                    transition: all 0.5s ease;
                    span{
                        position: relative;
                        z-index: 2;
                    }
                    &:after{
                        transition: width 0.5s ease;
                        content: "";
                        display: block;
                        position: absolute;
                        background: $DARK_GRAY_COLOR;
                        width: 0%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        z-index: 1;
                    }
                    &[target="_blank"]{
                        span.en:after{
                            width: 16px;
                            height: 16px;
                            content: "";
                            display: inline-block;
                            background: url(../images/common/ico_window_bk.svg) no-repeat center center;
                            background-size: contain;
                            margin-left: 5px;
                            vertical-align: middle;
                        }
                    }
                    &:hover{
                        color: $WHITE_COLOR;
                        opacity: 1;
                        &:after{
                            width: 100%;
                        }
                        &[target="_blank"]{
                            span.en:after{
                                background: url(../images/common/ico_window_wh.svg) no-repeat center center;
                            }
                        }
                    }
                    span.jp{
                        font-size: 1.1rem;
                        margin-top: 5px;
                        line-height: 1.2;
                        @include media(sp) {
                            display: none;
                        }
                    }
                }
            }
        }
        .menu-wrapper-bottom{
            width: 100%;
            margin-top: 30px;
            .sns-list{
                display: flex;
                justify-content: center;
                .menu-item{
                    margin: 0 10px;
                    a{
                        //background: $WHITE_COLOR;
                        color: $BLACK_COLOR;
                        display: block;
                        border-radius: 25px;
                        width: 50px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 2.0rem;
                        border: 1px solid $BLACK_COLOR;
                        transition: all 0.5s ease;
                        &:hover{
                            opacity: 1;
                            background: $BLACK_COLOR;
                            color: $WHITE_COLOR;
                        }
                    }
                }
            }
            .other-list{
                margin-top: 30px;
                .menu-item{
                    margin: 0 0 20px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    a{
                        text-align: center;
                        display: block;
                        padding: 10px 20px;
                        //background: $BEIGE_COLOR3;
                        color: $BLACK_COLOR;
                        font-size: 1.6rem;
                        border: 1px solid $BLACK_COLOR;
                        transition: all 0.5s ease;
                        &:hover{
                            opacity: 1;
                            background: $BLACK_COLOR;
                            color: $WHITE_COLOR;
                        }
                    }
                }
            }
        }
    }
}
.is-menu-open{
    #drawer_menu {
        pointer-events:initial;
        position: fixed;
        @include media(hd) {
        }
        @include media(sp) {
        }
    }
    &.is-landscape{
        #drawer_menu {
            #drawer_menu_bg{
                span{
                    &:before{
                        width: 300vw;
                        height: 300vw;
                        opacity: 1;
                    }
                }
            }
        }
    }
    &.is-portrait{
        #drawer_menu {
            #drawer_menu_bg{
                span{
                    &:before{
                        width: 300vh;
                        height: 300vh;
                        opacity: 1;
                    }
                }
            }
        }
    }
}
