// -------------------------------------------------------------------
//  header.scss
// -------------------------------------------------------------------
.header#header {
    z-index: 300;
    position: relative;
    height: 80px;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content:space-between;
    transition: all 0.35s ease;
    z-index: 100;
    width: 100%;
    background: transparent;
    position: fixed;
    &.trans{
        background: rgba(255,255,255,0.8) !important;
    }
    @include media(pc){
        height: $HEADER_HEIGHT_PC;
    }
    @include media(tb){
        height: $HEADER_HEIGHT_TB;
        &:hover{
        }
    }
    @include media(sp){
        height: $HEADER_HEIGHT_SP;
    }

    #header_logo{
        width: 180px;
        margin-left:40px;
        @include media(tb){
            width: 160px;
        }
        @include media(sp){
            margin-left: 20px;
            margin-right: 20px;
        }
        @include media(narrow){
            margin-left: 20px;
            margin-right: 20px;
        }
        a{
            color: $BLACK_COLOR;
        }
        img{
            width: 100%;
        }
        span{
            display: block;
            line-height: 1;
            font-family: $CENTURY_GOTHIC;
            font-weight: 100;
            margin-top: 5px;
            font-size: 1.3rem;
            text-align: center;
        }
    }

    .inner{
        @include flex_space_between;
    }
}

/* メニューボタン */
#menu_button{
    position: fixed;
    z-index: 999;
    top: 10px;
    right: 20px;
    transition: all 1.0s ease;
    display:block;
    font-size:11px;
    width:56px;
    height:56px;
    line-height:56px;
    text-decoration:none;
    text-align:center;
    background: $DARK_GRAY_COLOR;
    border-radius: 28px;
    &:hover{
        opacity: 1;
    }
    @include media(tb){
        top: 5px;
    }
    @include media(sp){
        top: -70px;
        right: -70px;
        width:140px;
        height:140px;
        border-radius: 70px;
    }
    span{
        background: $WHITE_COLOR;
        display:block;
        position:absolute;
        transition: all 0.3s ease-in-out;
        height: 2px;
    }
    span:nth-child(1) {
        top:20px;
        left:18px;
        width: 22px;
        @include media(sp){
            top:92px;
            left:30px;
        }
    }
    span:nth-child(2) {
        top:28px;
        left:18px;
        width: 16px;
        @include media(sp){
            top:100px;
            left:30px;
        }
    }
    span:nth-child(3) {
        top:36px;
        left:18px;
        width: 22px;
        @include media(sp){
            top:108px;
            left:30px;
        }
    }
}
.is-menu-open #menu_button{
    background: $DARK_GRAY_COLOR;
    span{
        background: $WHITE_COLOR;
    }
    span:nth-child(1) {
        top: 28px;
        left: 13px;
        transform: rotate(45deg);
        width: 30px;
        @include media(sp){
            top:100px;
            left:27px;
        }
    }
    span:nth-child(2) {
        transform: scaleX(0);
    }
    span:nth-child(3) {
        top: 28px;
        left: 13px;
        transform: rotate(135deg);
        width: 30px;
        @include media(sp){
            top:100px;
            left:27px;
        }
    }
    @include media(tb){
    }
}
