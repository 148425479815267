// ----------------------------------
//  button
// ----------------------------------

// ----------------------------------
//ボタンの共通定義
// ----------------------------------
%base-btn{
    @include flex_inner_center;
    cursor: pointer;
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 400px;
    //border-radius: 5px;
    overflow: hidden;
    &:after{
        font-family: $FONT_AWESOME;
        content: '\f054';
        position: absolute;
        top: 50%;
        right: 10px;
        @include media(tb){
            right: 10px;
        }
        transform: translateY(-50%);
    }
    i{
        position: absolute;
        right: 10px;
        @include media(tb){
            right: 10px;
        }
        top: 50%;
        transform: translateY(-50%);
        font-size: 1rem;
    }
    // PC -----------------
    @include media(pc){
        &:hover{
            opacity: 0.7;
        }
    }
    &.is-disabled{
        cursor: default;
    }
}

// ----------------------------------
//ボタンの色とhoverの定義
// ----------------------------------
%black-btn{
    color: $WHITE_COLOR !important;
    background: $BLACK_COLOR;
    border: 1px solid $BLACK_COLOR;
    transition: all 0.5s ease;
    &:hover{
        opacity: 0.7;
        color: $WHITE_COLOR !important;
        background: $BLACK_COLOR;
    }
}
%white-btn{
    color: $BLACK_COLOR !important;
    background: $WHITE_COLOR;
    border: 1px solid $BLACK_COLOR;
    transition: all 0.5s ease;
    &:hover{
        opacity: 1;
        color: $WHITE_COLOR !important;
        background: $BLACK_COLOR;
    }
    &[target="_blank"]{
        &:after{
            background: url(../images/common/ico_window_bk.svg) no-repeat center center;
        }
        &:hover:after{
            background: url(../images/common/ico_window_wh.svg) no-repeat center center;
        }
    }
}
// ----------------------------------
//ボタンサイズ（高さのみ）の定義
// ----------------------------------
%small-btn{
    height: 20px;
    font-size: 1.2rem;
    @include media(tb){
        font-size: 1.0rem;
    }
}
%midium-btn{
    height: 40px;
    font-size: 1.3rem;
    @include media(tb){
        font-size: 1.1rem;
    }
}
%large-btn{
    height: 60px;
    font-size: 1.8rem;
    @include media(tb){
        font-size: 1.4rem;
        height: 60px;
    }
    @include media(sp){
        font-size: 1.4rem;
        height: 50px;
    }
}
%huge-btn{
    height: 70px;
    font-size: 1.8rem;
    @include media(tb){
        font-size: 1.4rem;
        height: 60px;
    }
    @include media(sp){
        font-size: 1.4rem;
        height: 60px;
    }
}

// ----------------------------------
//具体的なボタンの定義
// ----------------------------------
.btn-black{
    @extend %base-btn;
    @extend %black-btn;
    @extend %large-btn;
}
.btn-white{
    @extend %base-btn;
    @extend %white-btn;
    @extend %large-btn;
}
