// -------------------------------------------------------------------
//  story.scss
// -------------------------------------------------------------------
.story{
    background-color: $BEIGE_COLOR1;
    .page-title-slide{
        .swiper-slide a{
            display: block;
            position: relative;
            color: $BLACK_COLOR;
            .story-text{
                background: rgba(0,0,0,0.3);
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 60px;
                color: $WHITE_COLOR;
                @include media(sp){
                    padding: 40px;
                }
                .story-time{
                    line-height: 1.4;
                    display: block;
                    font-size: 1.4rem;
                    @include media(tb){
                        font-size: 1.2rem;
                    }
                }
                .story-title{
                    line-height: 1.4;
                    font-size: 2.4rem;
                    @include media(tb){
                        font-size: 2.0rem;
                    }
                    @include media(sp){
                        font-size: 1.6rem;
                    }
                }
                .story-category{
                    margin-top: 10px;
                    span{
                        display: inline-block;
                        border: 1px solid $WHITE_COLOR;
                        padding: 5px 10px;
                        font-size: 1.2rem;
                        @include media(tb){
                            padding: 2px 4px;
                            font-size: 1.0rem;
                        }
                        @include media(sp){
                            font-size: 1.0rem;
                        }
                    }
                }
            }
        }
    }
    .sec-story{
        .story-container{
            .story-category-header{
                max-width: 1000px;
                margin: 0 auto;
                margin-bottom: 40px;
                @include media(tb){
                    margin-bottom: 30px;
                }
                @include media(sp){
                    margin-bottom: 20px;
                }
                .story-category-title{
                    line-height: 1.4;
                    text-align: center;
                    font-size: 4.0rem;
                    margin-bottom: 20px;
                    @include media(tb){
                        margin-bottom: 10px;
                        font-size: 3.0rem;
                    }
                    @include media(sp){
                        font-size: 2.0rem;
                    }
                }
            }
            .story-list{
            }
            .story-item{
                background: $WHITE_COLOR;
                padding: 40px;
                margin-bottom: 40px;
                border-radius: 30px;
                @include media(tb){
                    padding: 30px;
                    margin-bottom: 30px;
                    border-radius: 20px;
                }
                @include media(sp){
                    padding: 20px;
                    margin-bottom: 20px;
                    border-radius: 10px;
                }
                a{
                    color: $BLACK_COLOR;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                }
                .story-image{
                    width: 35%;
                    border-radius: 20px;
                    overflow: hidden;
                    height: 30vw;
                    max-height: 300px;
                    @include media(wide){
                      height: 26vw;
                    }
                    @include media(hd){
                      height: 22vw;
                    }
                    @include media(tb){
                        border-radius: 10px;
                    }
                    @include media(sp){
                        border-radius: 0px;
                        width: 100%;
                        height: 50vw;
                    }
                }
                .story-text{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: calc(65% - 40px);
                    @include media(tb){
                        width: calc(65% - 30px);
                    }
                    @include media(sp){
                        width:100%;
                        margin-top: 10px;
                    }
                    line-height: 1.4;
                    .story-title{
                        font-size: 1.8rem;
                        @include media(tb){
                            font-size: 1.6rem;
                        }
                        @include media(sp){
                            font-size: 1.4rem;
                        }
                    }
                    .story-category{
                        margin-top: 10px;
                        span{
                            display: inline-block;
                            border: 1px solid $BLACK_COLOR;
                            padding: 2px 4px;
                            font-size: 1.2rem;
                        }
                    }
                    .story-description{
                        margin-top: 20px;
                        @include media(tb){
                            margin-top: 10px;
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }

    }
}
.story-index{
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 100px;
    @include media(wide){
        margin-bottom: 90px;
    }
    @include media(hd){
        margin-bottom: 80px;
    }
    @include media(tb){
        margin-bottom: 60px;
    }
    @include media(sp){
        margin-bottom: 40px;
    }
    .story-index-title{
        text-align: center;
        font-size: 2.4rem;
        margin-bottom: 20px;
        @include media(tb){
            margin-bottom: 10px;
            font-size: 2.0rem;
        }
        @include media(sp){
            margin-bottom: 10px;
            font-size: 1.6rem;
        }
    }
    .select-list{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .select-item{
        width: calc(33.33% - 10px);
        @include media(sp){
            width: 100%;
            margin-bottom: 10px;
        }
        .select-wrap select{
            @include media(pc){
                padding: 20px;
            }
        }
    }
}

