$duration-time: 1.0s;
$easing: ease;

@mixin animation-settings {
  animation-duration: $duration-time;
  animation-timing-function: $easing;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;
}
@keyframes animate-panel {
  0% {
    transform-origin: left top;
    transform: scale(0, 1);
  }
  49% {
    transform-origin: left top;
    transform: scale(1, 1);
  }
  50% {
    transform-origin: right top;
    transform: scale(1, 1);
  }
  100% {
    transform-origin: right top;
    transform: scale(0, 1);
  }
}
@keyframes animate-content {
  0% {
    visibility: hidden;
  }
  49% {
    visibility: hidden;
  }
  50% {
    visibility: visible;
  }
  100% {
    visibility: visible;
  }
}

.mask-wrap {
  height: 100%;
  width: 100%;
  display: inline-block;
  //margin: 0 30px 30px 30px;
    .mask-inner {
      height: 100%;
      width: 100%;
      position: relative;
      visibility: hidden;
        &::after {
          content: "";
          background: #000;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          transform-origin: left top;
          transform: scale(0, 1);
          transition: all 0.3s $easing 0s;
          visibility: visible;
        }
        &.start {
          animation-name: animate-content;
          @include animation-settings;
            &::after {
              animation-name: animate-panel;
              @include animation-settings;
            }
        }
    }
}
