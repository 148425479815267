// ----------------------------------
//  accordion
// ----------------------------------
.accordion-container{
}
.btn-accordion{
    display: block;
    position: relative;
    padding-right: 24px;
    &.down:after{
        position: absolute;
        right: 5px;
        top: 55%;
        transform: translateY(-50%);
        font-family: $FONT_AWESOME;
        content: "\f078";
        color: $BLACK_COLOR;
    }
    &.up:after{
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        font-family: $FONT_AWESOME;
        content: "\f077";
        color: $BLACK_COLOR;
    }
}
.accordion-contents{
    display: none;
}
