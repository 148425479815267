// ----------------------------------
//  swiper
// ----------------------------------
.swiper{
    position: relative;
    padding-bottom: 30px;
    @include media(sp){
        margin-right: -20px;
        margin-left: -20px;
    }
}
.swiper-button-next{
    margin: 0;
    background: none;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    @include flex_inner_center;
    @include media(pc){
        right: -60px;
    }
    @include media(tb){
        right: -30px;
    }
    @include media(sp){
        right: 10px;
    }
    &:after{
        font-family: $FONT_AWESOME;
        font-size: 4rem;
        content: "\f054";
        @include media(tb){
            font-size: 3rem;
        }
        @include media(sp){
            font-size: 2rem;
        }
    }
}
.swiper-button-prev{
    margin: 0;
    background: none;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    @include flex_inner_center;
    @include media(pc){
        left: -60px;
    }
    @include media(tb){
        left: -30px;
    }
    @include media(sp){
        left: 10px;
    }
    &:after{
        font-family: $FONT_AWESOME;
        font-size: 4rem;
        content: "\f053";
        @include media(tb){
            font-size: 3rem;
        }
        @include media(sp){
            font-size: 2rem;
        }
    }
}
.swiper-pagination{
    width: 100%;
    bottom: 0px;
    z-index: 1;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.swiper-button-disabled{
    opacity: 0 !important;
}
.swiper-pagination-bullet{
    margin: 0 4px;
    width: 30px;
    height: 4px;
    border-radius: 0;
    background: $BEIGE_COLOR3;
}
.swiper-pagination-bullet-active{
    background: $BEIGE_COLOR4;
}


