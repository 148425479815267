// -------------------------------------------------------------------
//  service.scss
// -------------------------------------------------------------------
.service{
    .sec-management{
        .management-list{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .management-item{
            width: calc(50% - 50px);
            margin-top: 100px;
            @include media(wide){
                width: calc(50% - 40px);
                margin-top: 80px;
            }
            @include media(hd){
                width: calc(50% - 20px);
                margin-top: 20px;
                margin-bottom: 40px;
            }
            @include media(sp){
                width: 100%;
                margin-top: 20px;
                margin-bottom: 40px;
            }
            .management-item-ttl{
                border: 1px solid $BLACK_COLOR;
                text-align: center;
                font-weight: normal;
                line-height: 1.4;
                min-height: 300px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @include media(tb){
                    min-height: 200px;
                }
                @include media(sp){
                    min-height: 160px;
                }
                .en{
                    display: block;
                    font-size: 3.6rem;
                    @include media(tb){
                        font-size: 2.4rem;
                    }
                    @include media(sp){
                        font-size: 2.0rem;
                    }
                }
                .jp{
                    display: block;
                    font-size: 1.4rem;
                    margin-top: 20px;
                    @include media(tb){
                        font-size: 1.2rem;
                    }
                    @include media(sp){
                        margin-top: 10px;
                    }
                }
            }
            .management-item-description{
                font-size: 1.4rem;
                padding: 20px;
                @include media(tb){
                    padding: 20px 0;
                    font-size: 1.2rem;
                }
                @include media(sp){
                    padding: 10px 0 20px;
                }
            }
            .btn-black{
                margin: 0 auto;
            }
        }
    }
    .sec-produce{
        .produce-item{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 100px;
            align-items: center;
            @include media(wide){
                margin-top: 80px;
                @include media(hd){
                    margin-top: 20px;
                    margin-bottom: 60px;
                }
            }
            .produce-image{
                width: calc(50% - 50px);
                @include media(wide){
                    width: calc(50% - 40px);
                }
                @include media(hd){
                    width: calc(50% - 20px);
                }
                @include media(tb){
                    width: 100%;
                }
                .swiper-slide{
                    height: 400px;
                    @include media(tb){
                        height: 60vw;
                    }
                }
            }
            .produce-text{
                width: calc(50% - 50px);
                @include media(wide){
                    width: calc(50% - 40px);
                }
                @include media(hd){
                    width: calc(50% - 20px);
                }
                @include media(tb){
                    width: 100%;
                    margin-top: 20px;
                }
                .produce-logo{
                    margin-bottom: 20px;
                }
                .produce-name{
                    line-height: 1.4;
                    .en{
                        display: block;
                        font-size: 3.0rem;
                    }
                    .jp{
                        display: block;
                        font-size: 1.2rem;
                    }
                }
                .produce-description{
                    margin-top: 20px;
                    font-size: 1.4rem;
                    @include media(tb){
                        margin-top: 10px;
                    }
                }
                .produce-url{
                    margin-top: 20px;
                    font-size: 1.4rem;
                    @include media(tb){
                        margin-top: 10px;
                    }
                    a{
                        color: $BASE_TEXT_COLOR;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
